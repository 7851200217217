import { Toast } from 'components/Common/Toast'
import { fetchClients } from 'core/http/fetchClients'
import { useFormik } from 'formik'
import { formatCurrencyToApi } from 'helpers/formatCurrencyToApi'
import { useHttp } from 'hooks/useHttp'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import * as Yup from 'yup'
import { createPaymentTable } from '../http/createPaymentTable'
import { TableGroupCollapseItem } from './components/TableGroupCollapseItem'

export function CloneTableModal({ isOpen, onToggle, table }) {
  const [valuesPayload, setValuesPayload] = useState({})

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [isCloningTable, setIsCloningTable] = useState(false)

  const [selectedClient, setSelectedClient] = useState({})

  const {
    data: clients,
    isLoading: isClientsLoading,
    isSuccess
  } = useHttp(['clients'], fetchClients, {
    refetchOnWindowFocus: false,
    enabled: !!table,
    staleTime: 1000 * 60 * 5 // 5 minutes
  })

  const queryClient = useQueryClient()

  const notifySuccess = () => {
    toast.success('Tabela de pagamento criada com sucesso')
  }

  const notifyError = () => {
    toast.error('Erro ao criar tabela de pagamento')
  }

  function handleSelectClient(newClient) {
    setSelectedClient(newClient)
  }

  useEffect(() => {
    if (table) {
      setValuesPayload(table.valores_pgto)

      if (isSuccess) {
        const [client] = clients.filter(client => client.id === table.client_id)

        setSelectedClient(client)
      }
    }
  }, [table, isSuccess])

  function handleToggleModal() {
    onToggle()
  }

  const handleChangeValues = useCallback(
    (gamePayload, newValue) => {
      const [newValuesPayload] = Object.entries(valuesPayload).map(
        ([key, _]) => {
          const returnObject = valuesPayload

          let globalValue = returnObject[gamePayload.grupo].globalValue

          const valuesAreEqual = returnObject[gamePayload.grupo].values.every(
            value => {
              if (
                value.pagamento.valor ===
                returnObject[gamePayload.grupo].values[0].pagamento.valor
              ) {
                return true
              }
            }
          )

          if (returnObject[gamePayload.grupo].values.length === 1) {
            globalValue = newValue
          } else if (valuesAreEqual) {
            globalValue = '0,00'
          }

          if (key === gamePayload.grupo) {
            returnObject[gamePayload.grupo] = {
              ...returnObject[gamePayload.grupo],
              globalValue,
              values: returnObject[gamePayload.grupo].values.map(value => {
                if (
                  value.pagamento.codigo_jogo ===
                  gamePayload.pagamento.codigo_jogo
                ) {
                  return {
                    ...value,
                    pagamento: {
                      ...value.pagamento,
                      valor: newValue
                    }
                  }
                }

                return value
              })
            }
          }

          return returnObject
        }
      )

      setValuesPayload(newValuesPayload)
      forceUpdate()
    },
    [valuesPayload]
  )

  const handleChangeGlobalValues = useCallback(
    (group, newValue) => {
      const [newValuesPayload] = Object.entries(valuesPayload).map(
        ([key, values]) => {
          const returnObject = valuesPayload

          if (key === group) {
            returnObject[key] = {
              globalValue: newValue,
              values: values.values.map(value => ({
                ...value,
                pagamento: {
                  ...value.pagamento,
                  valor: newValue
                }
              }))
            }
          }

          return returnObject
        }
      )

      setValuesPayload(newValuesPayload)
      forceUpdate()
    },
    [valuesPayload]
  )

  async function handleCloneTable(values) {
    setIsCloningTable(true)

    const requestPayload = {
      id_unidade: selectedClient?.id,
      alias: values.alias,
      indice: values.index,
      valores_pgto: []
    }

    Object.values(valuesPayload).forEach(value => {
      value.values.forEach(game => {
        requestPayload.valores_pgto.push({
          valor: formatCurrencyToApi(game.pagamento.valor).replace('.00', ''),
          descricao: game.pagamento.descricao,
          codigo_jogo: game.pagamento.codigo_jogo
        })
      })
    })

    try {
      const createdTable = await createPaymentTable(requestPayload)

      const newPaymentTb = {
        id: createdTable.id,
        client_id: createdTable.id_unidade,
        alias: createdTable.alias,
        banca_alias: selectedClient.alias,
        indice: values.index,
        valores_pgto: valuesPayload
      }

      queryClient.setQueryData('paymentTb', currentData => {
        return [...currentData, newPaymentTb]
      })

      notifySuccess()
      handleToggleModal()
    } catch (err) {
      notifyError()
    }

    setIsCloningTable(false)
  }

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      alias: Yup.string().required('Alias obrigatório'),
      index: Yup.number().required('Índice obrigatório')
    }),
    initialValues: {
      client: table?.banca_alias,
      alias: table?.alias,
      index: table?.indice
    },
    onSubmit: values => {
      handleCloneTable(values)
    }
  })

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleToggleModal} scrollable>
        <ModalHeader toggle={handleToggleModal} tag="h4">
          Duplicar tabela de pagamento
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Col>
              <CardTitle className="mb-4">Informações</CardTitle>
              <div className="mb-3">
                <div className="mb-3">
                  <label className="control-label">Banca</label>
                  <Select
                    value={selectedClient || []}
                    onChange={value => handleSelectClient(value)}
                    placeholder="Selecionar cliente"
                    options={
                      isClientsLoading
                        ? [{ label: 'Carregando...', value: 'Carregando...' }]
                        : clients
                    }
                  />
                </div>
              </div>

              <div className="mb-3">
                <Label className="form-label">Alias</Label>
                <Input
                  name="alias"
                  type="text"
                  placeholder="Alias"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.alias || ''}
                  invalid={
                    validation.touched.alias && validation.errors.alias
                      ? true
                      : false
                  }
                />
                {validation.touched.alias && validation.errors.alias ? (
                  <FormFeedback type="invalid">
                    {validation.errors.alias}
                  </FormFeedback>
                ) : null}
              </div>

              <div>
                <Label className="form-label">Índice</Label>
                <Input
                  name="index"
                  type="number"
                  placeholder="Índice"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.index || ''}
                  invalid={
                    validation.touched.index && validation.errors.index
                      ? true
                      : false
                  }
                />
                {validation.touched.index && validation.errors.index ? (
                  <FormFeedback type="invalid">
                    {validation.errors.index}
                  </FormFeedback>
                ) : null}
              </div>

              <CardTitle className="mt-4">Pagamentos</CardTitle>
              <div className="accordion accordion-flush" id="accordinFlush">
                {valuesPayload &&
                  Object.entries(valuesPayload).map(([tableGroup, value]) => (
                    <TableGroupCollapseItem
                      key={tableGroup}
                      group={tableGroup}
                      globalValue={value.globalValue}
                      values={value.values}
                      onChangeValues={handleChangeValues}
                      onChangeGlobalValues={handleChangeGlobalValues}
                    />
                  ))}
              </div>
            </Col>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {isCloningTable && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Salvar
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
