import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://trivoweb.com/api/admin',
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
  },
  params: {
    VERSAO: 3
  }
})