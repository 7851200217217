import Breadcrumbs from 'components/Common/Breadcrumb'
import { Pagination } from 'components/Common/Pagination'
import React, { useMemo, useState } from 'react'
import { Search } from 'react-bootstrap-table2-toolkit'
import MetaTags from 'react-meta-tags'
import { useQuery } from 'react-query'
import {
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import { api } from 'services/api'

export function Lotteries() {
  const { SearchBar } = Search

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [isLotteriesModalOpen, setIsLotteriesModalOpen] = useState(false)

  const [client, setClient] = useState(null)

  async function fetchLotteries() {
    const { data } = await api.get('/lotteries')

    return data
  }

  const { data, isSuccess } = useQuery(['lotteries'], fetchLotteries)

  const lotteries = useMemo(() => {
    return isSuccess ? data : []
  }, [isSuccess, data])

  const columns = useMemo(() => {
    return [
      {
        accessor: 'id',
        Header: 'Id',
        width: 20
      },
      {
        accessor: 'codigo_loteria',
        Header: 'Código',
        width: 20
      },
      {
        accessor: 'alias',
        Header: 'Nome'
      },
      {
        accessor: '',
        Header: 'Ações',
        width: 20,
        Cell: props => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop cursor-pointer"
                tag="div"
              >
                <i className="mdi mdi-pencil font-size-14 text-success me-1" />
                <span className="font-size-14 text-success text-normal">
                  Editar
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => handleCallModalAction('Profile', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-cookie font-size-16 me-1" /> Perfil
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCallModalAction('Payment', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-cookie font-size-16 me-1" /> Pagamento
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCallModalAction('Lotteries', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-cookie font-size-16 me-1" /> Loterias
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter, pageSize, pageIndex },
    page,
    setGlobalFilter,
    pageCount,
    gotoPage,
    nextPage,
    previousPage
  } = useTable(
    {
      columns,
      data: lotteries,
      initialState: {
        pageSize: 15
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Loterias" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">Clientes </CardTitle>
                      <p className="card-title-desc">Tabela de clientes</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Buscar"
                            onSearch={setGlobalFilter}
                            searchText={globalFilter}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <th
                                key={index}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                          prepareRow(row)
                          return (
                            <tr key={index} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td key={index} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-center">
                    <span>Total de {lotteries?.length} registros</span>
                    <Pagination
                      onNextPage={nextPage}
                      onPreviousPage={previousPage}
                      onChangePage={page => gotoPage(page)}
                      registersPerPage={pageSize}
                      totalPageCount={lotteries?.length}
                      currentPage={pageIndex + 1}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
