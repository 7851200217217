import { Toast } from 'components/Common/Toast'
import { updateClientLotteries } from 'pages/Clients/http/updateClientLotteries'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { LotteryGroupCollapseItem } from './LotteryGroupCollapseItem'

export function LotteriesModal({ isOpen, onToggle, client }) {
  const [lotteriesPayload, setLotteriesPayload] = useState(null)
  const [shouldApplyToAllUnits, setShouldApplyToAllUnits] = useState(false)
  const [isSavingLotteries, setIsSavingLotteries] = useState(false)

  const queryClient = useQueryClient()

  const notifySuccess = () => {
    toast.success('Loterias alteradas com sucesso')
  }

  const notifyError = () => {
    toast.error('Erro ao alterar loterias')
  }

  useEffect(() => {
    if (client) {
      setLotteriesPayload(client.loterias)
    }
  }, [client])

  const handleChangeLotteries = useCallback(
    (group, lotteries) => {
      setLotteriesPayload(currentState => ({
        ...currentState,
        [group]: lotteries
      }))
    },
    [client, lotteriesPayload]
  )

  async function handleSaveLotteries() {
    const currentLotteriesSetup = JSON.stringify(client.loterias)
    const newLotteriesSetup = JSON.stringify(lotteriesPayload)

    if (currentLotteriesSetup === newLotteriesSetup) {
      return
    }

    setIsSavingLotteries(true)

    const requestPayload = []

    Object.entries(lotteriesPayload).forEach(([group, lotteries]) => {
      if (
        JSON.stringify(client.loterias[group]) === JSON.stringify(lotteries)
      ) {
        return
      }

      lotteries.forEach(lottery => {
        requestPayload.push({
          id_unidade: lottery.id_unidade,
          codigo_loteria: lottery.codigo_loteria,
          active: lottery.active
        })
      })
    })

    try {
      const ids_lotteries = []

      Object.values(lotteriesPayload).forEach(lotteries => {
        lotteries
          .filter(lottery => lottery.active === 1)
          .forEach(activeLottery => {
            ids_lotteries.push(activeLottery.codigo_loteria)
          })
      })

      await updateClientLotteries({
        lotteries: requestPayload,
        shouldApplyToAllUnits,
        ids_lotteries,
        id_parent: client.id_parent,
        client_id: client.id
      })

      const newClientData = {
        ...client,
        loterias: lotteriesPayload
      }

      queryClient.setQueryData('clients', currentData => {
        return currentData.map(client => {
          if (client.id === newClientData.id) {
            return newClientData
          }
          return client
        })
      })

      notifySuccess()
      onToggle()
    } catch (err) {
      notifyError()
    }

    setIsSavingLotteries(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle()
          setLotteriesPayload(null)
        }}
        scrollable
      >
        <ModalHeader
          toggle={() => {
            onToggle()
            setLotteriesPayload(null)
          }}
          tag="h4"
        >
          Loterias
        </ModalHeader>
        <ModalBody>
          <Col>
            <div className="accordion accordion-flush" id="accordinFlush">
              {client &&
                Object.entries(client.loterias).map(
                  ([lotteryGroup, lotteries]) => (
                    <LotteryGroupCollapseItem
                      key={lotteryGroup}
                      group={lotteryGroup}
                      lotteries={lotteries}
                      onChangeLotteries={handleChangeLotteries}
                    />
                  )
                )}
            </div>

            <div className="text-end d-flex align-center justify-between mt-12">
              <div className="d-flex align-center">
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  checked={shouldApplyToAllUnits}
                  id="lotteryCheck"
                  onClick={() =>
                    setShouldApplyToAllUnits(currentState => !currentState)
                  }
                />
                <label className="form-check-label ml-6" htmlFor="lotteryCheck">
                  Aplicar a todas unidades
                </label>
              </div>
              <button
                className="btn btn-success save-user"
                onClick={handleSaveLotteries}
                disabled={isSavingLotteries}
              >
                {isSavingLotteries && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
                Salvar
              </button>
            </div>
          </Col>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
