import React, { createContext, useCallback, useContext } from 'react'
import { api } from 'services/api'

const AuthContext = createContext({})

function AuthProvider({ children }) {
  const signIn = useCallback(async ({ email, password }) => {
    if (email === 'admin' && password === 'admin') {
      localStorage.setItem(
        'trivo-dash@user',
        JSON.stringify({ email, password })
      )
    }
  }, [])

  return (
    <AuthContext.Provider value={{ signIn }}>{children}</AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
