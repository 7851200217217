import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

export function UsersModal({ isOpen, onToggle, client }) {
  return (
    <Modal isOpen={isOpen} toggle={onToggle} scrollable>
      <ModalHeader toggle={onToggle} tag="h4">
        Usuários
      </ModalHeader>
      <ModalBody>
        <Col>
          {client &&
            client.users.map(user => (
              <div key={user.id} className="d-flex pv-8">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={user.active}
                  id="lotteryCheck"
                  onChange={() => {}}
                  onClick={() => {}}
                />

                <Col className="ml-12">
                  <h5 className="text-truncate font-size-14 text-dark mb-0">
                    {user.name}
                  </h5>
                  <p className="text-muted mb-0">{user.email}</p>
                </Col>

                <div className="d-flex align-center">
                  <span className="ml-4">{user.profile}</span>
                </div>
              </div>
            ))}
        </Col>
        <Row>
          <Col>
            <div className="text-end">
              <button type="submit" className="btn btn-success save-user">
                Salvar
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
