export function validateUser(params) {
  const errors = {}

  if (!!params.client === false) {
    errors.client = {
      message: 'Selecione a banca'
    }
  }

  if (!!params.client && !!params.profile === false) {
    errors.profile = {
      message: 'Selecione o perfil'
    }
  }

  if (!!params.profile) {
    if (params.profile.value === 'Cobrador ' && !!params.collector === false) {
      errors.collector = {
        message: 'Selecione o cobrador'
      }
    }

    if (
      params.profile.value === 'Gerente Regiao' &&
      params.regions.length === 0
    ) {
      errors.collector = {
        message: 'Selecione a região'
      }
    }
  }

  return errors
}
