import { api } from 'services/api'

export async function fetchClients({ forSelectInput = true }) {
  const { data } = await api.get('/clients')

  if (forSelectInput) {
    return data.map(client => ({
      ...client,
      label: client.alias,
      value: client.alias
    }))
  }

  return data
}
