import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input'
import Select from 'react-select'
import { CardTitle, Col, FormFeedback, Input, Label } from 'reactstrap'

export function InformationsSection({
  validation,
  onChangeFootQuotationType,
  footQuotationType
}) {
  return (
    <>
      <CardTitle className="mb-4">Informações</CardTitle>

      <Col lg="12">
        <div className="mb-3">
          <Label className="form-label">Título</Label>
          <Input
            name="header_title"
            type="text"
            placeholder="Título no cabeçalho do recibo da pule"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.header_title || ''}
            invalid={
              validation.touched.header_title && validation.errors.header_title
                ? true
                : false
            }
          />
          {validation.touched.header_title && validation.errors.header_title ? (
            <FormFeedback type="invalid">
              {validation.errors.header_title}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Mensagem aposta</Label>
          <Input
            name="msg_apostas"
            type="text"
            placeholder="Mensagem de conclusão de aposta"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.msg_apostas || ''}
            invalid={
              validation.touched.msg_apostas && validation.errors.msg_apostas
                ? true
                : false
            }
          />
          {validation.touched.msg_apostas && validation.errors.msg_apostas ? (
            <FormFeedback type="invalid">
              {validation.errors.msg_apostas}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg="4">
        <div className="mb-3">
          <Label className="form-label">Reclame dias</Label>
          <Input
            name="reclame_dias"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.reclame_dias || ''}
            invalid={
              validation.touched.reclame_dias && validation.errors.reclame_dias
                ? true
                : false
            }
          />
          {validation.touched.reclame_dias && validation.errors.reclame_dias ? (
            <FormFeedback type="invalid">
              {validation.errors.reclame_dias}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg="4">
        <div className="mb-3">
          <Label className="form-label">Limite jogo</Label>
          <CurrencyInput
            name="limite_jogo"
            prefix="R$"
            type="money"
            inputMode="decimal"
            className="form-control input-color"
            placeholder="0,00"
            decimalSeparator=","
            thousandSeparator="."
            onChangeEvent={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.limite_jogo || ''}
            invalid={
              validation.touched.limite_jogo && validation.errors.limite_jogo
                ? true
                : false
            }
          />
          {validation.touched.limite_jogo && validation.errors.limite_jogo ? (
            <FormFeedback type="invalid">
              {validation.errors.limite_jogo}
            </FormFeedback>
          ) : null}
        </div>
      </Col>

      <Col lg="4">
        <div className="mb-3">
          <Label className="form-label">Tempo limite para cancelar pule</Label>
          <Input
            name="pule_cancela"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.pule_cancela || ''}
            invalid={
              validation.touched.pule_cancela && validation.errors.pule_cancela
                ? true
                : false
            }
          />
          {validation.touched.pule_cancela && validation.errors.pule_cancela ? (
            <FormFeedback type="invalid">
              {validation.errors.pule_cancela}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg="6">
        <label className="control-label">Futebol cotação</label>
        <div className="mb-3">
          <Select
            value={footQuotationType}
            onChange={onChangeFootQuotationType}
            placeholder="Selecionar"
            options={[
              { label: 'Acima', value: 'Acima' },
              { label: 'Abaixo', value: 'Abaixo' }
            ]}
          />
        </div>
      </Col>
      <Col lg="6">
        <label className="control-label">Futebol cotação valor</label>
        <div className="mb-3">
          <CurrencyInput
            name="foot_cotacao_valor"
            prefix="R$"
            type="money"
            inputMode="decimal"
            className="form-control input-color"
            placeholder="0,00"
            decimalSeparator=","
            thousandSeparator="."
            onChangeEvent={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.foot_cotacao_valor || ''}
            invalid={
              validation.touched.foot_cotacao_valor &&
              validation.errors.foot_cotacao_valor
                ? true
                : false
            }
          />
        </div>
      </Col>
      <Col lg="12">
        <label className="control-label">Futebol Ligas</label>
        <div className="mb-3">
          <Select
            value={[]}
            isMulti={true}
            onChange={() => {}}
            placeholder="Selecionar"
            options={[]}
          />
        </div>
      </Col>
    </>
  )
}
