import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useFlexLayout, useTable } from 'react-table'
import {
  Button,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Row
} from 'reactstrap'
import * as Yup from 'yup'

export function ListedsSection({ params, onAddListed }) {
  const listeds = useMemo(() => {
    if (params.cotadas) {
      return params.cotadas
    }

    return []
  }, [params])

  const columns = useMemo(() => {
    return [
      {
        accessor: 'codigo_jogo',
        Header: 'Código jogo'
      },
      {
        accessor: 'numero',
        Header: 'Número'
      },
      {
        accessor: 'taxa',
        Header: 'Taxa'
      }
    ]
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: listeds,
        initialState: {
          pageSize: 15
        }
      },
      useFlexLayout
    )

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      codigo_jogo: '',
      numero: '',
      taxa: ''
    },
    validationSchema: Yup.object({
      codigo_jogo: Yup.string().required('Código do jogo obrigatório'),
      numero: Yup.number().required('Número obrigatório'),
      taxa: Yup.number().required('Taxa obrigatória')
    }),
    onSubmit: values => {
      onAddListed(values)
      validation.resetForm()
    }
  })

  return (
    <>
      <Col lg="12">
        <CardTitle className="mb-4 mt-4">Cotadas</CardTitle>
        {!!listeds.length && (
          <div className="table-responsive">
            <table
              className="table align-middle table-nowrap"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th key={index} {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row)
                  return (
                    <tr key={index} {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        <Form>
          <Row>
            <Col lg="4">
              <div className="mb-3">
                <Input
                  name="codigo_jogo"
                  type="text"
                  placeholder="Código do jogo aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.codigo_jogo || ''}
                  invalid={
                    validation.touched.codigo_jogo &&
                    validation.errors.codigo_jogo
                      ? true
                      : false
                  }
                />
                {validation.touched.codigo_jogo &&
                validation.errors.codigo_jogo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.codigo_jogo}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Input
                  name="numero"
                  type="number"
                  placeholder="Número aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.numero || ''}
                  invalid={
                    validation.touched.numero && validation.errors.numero
                      ? true
                      : false
                  }
                />
                {validation.touched.numero && validation.errors.numero ? (
                  <FormFeedback type="invalid">
                    {validation.errors.numero}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Input
                  name="taxa"
                  type="number"
                  placeholder="Taxa aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.taxa || ''}
                  invalid={
                    validation.touched.taxa && validation.errors.taxa
                      ? true
                      : false
                  }
                />
                {validation.touched.taxa && validation.errors.taxa ? (
                  <FormFeedback type="invalid">
                    {validation.errors.taxa}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="6">
              <></>
            </Col>
            <Col lg="6" className="d-flex justify-end">
              <Button
                onClick={() => {
                  validation.handleSubmit()
                }}
                color="primary"
                className="font-16 btn-block btn btn-primary ml-auto"
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Adicionar cotada
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  )
}
