import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { CardTitle, Col, Form, Input, Label, Row, TabPane } from 'reactstrap'

const DEFAULT_PERMISSIONS = {
  cadastra_resultado: 0,
  cadastra_financeiro: 1,
  cadastra_lancamento: 1,
  painel_financeiro: 1,
  painel_dispositivos: 1,
  painel_resultados: 1,
  painel_cobrancas: 1,
  painel_saldo: 1,
  painel_pules: 1
}

const DEFAULT_ROLES = [
  'ROLE_UNIDADE',
  'ROLE_OPERADOR',
  'ROLE_COBRADOR',
  'ROLE_GERENTE',
  'ROLE_ADMIN'
]

const UserStepElement = (_, ref) => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [permissions] = useState(DEFAULT_PERMISSIONS)
  const [roles] = useState(DEFAULT_ROLES)

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return {
          username,
          email,
          password,
          permissions,
          roles
        }
      }
    }
  })

  return (
    <TabPane tabId={4}>
      <Form>
        <Row>
          <Col lg="12">
            <CardTitle className="mb-4">Informações</CardTitle>
            <Form>
              <Row>
                <Col lg="4">
                  <div className="mb-3">
                    <Label className="form-label">Nome</Label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Nome aqui"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="mb-3">
                    <Label className="form-label">E-mail</Label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="E-mail aqui"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="mb-3">
                    <Label className="form-label">Senha</Label>
                    <Input
                      name="password"
                      type="text"
                      placeholder="Senha aqui"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
    </TabPane>
  )
}

export const UserStep = forwardRef(UserStepElement)
