import { api } from '../../services/api'

export async function fetchRegions({ id_parent, forSelectInput = true }) {
  const { data } = await api.get('/regions', {
    params: {
      id_parent
    }
  })

  if (forSelectInput) {
    return data.map(region => ({
      ...region,
      label: region.title,
      value: region.title
    }))
  }

  return data
}
