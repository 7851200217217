import { useAuth } from 'contexts/AuthContext'
import React from 'react'
import MetaTags from 'react-meta-tags'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { AlterationRegister } from './Tables/AlterationRegister'

export const Dashboard = () => {
  const { user } = useAuth()

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Painel" />

          <AlterationRegister />
        </Container>
      </div>
    </>
  )
}
