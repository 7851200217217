import { useFormik } from 'formik'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { Col, Form, FormFeedback, Input, Label, Row, TabPane } from 'reactstrap'

const ProfileStepElement = ({ onToggleTab }, ref) => {
  const isFirstRender = useRef(true)
  const [filledValues, setFilledValues] = useState({})

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      alias: '',
      slug: '',
      uuid: '',
      id: '',
      region: '',
      comission: ''
    }
  })

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    setFilledValues(validation.values)
  }, [onToggleTab])

  useImperativeHandle(ref, () => {
    return {
      getValues: () => filledValues
    }
  })

  return (
    <TabPane tabId={1}>
      <Form>
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Nome</Label>
              <Input
                name="alias"
                type="text"
                placeholder="Nome do cliente aqui"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.alias || ''}
                invalid={
                  validation.touched.alias && validation.errors.alias
                    ? true
                    : false
                }
              />
              {validation.touched.alias && validation.errors.alias ? (
                <FormFeedback type="invalid">
                  {validation.errors.alias}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Slug</Label>
              <Input
                name="slug"
                type="text"
                placeholder="Slug aqui"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.slug || ''}
                invalid={
                  validation.touched.slug && validation.errors.slug
                    ? true
                    : false
                }
              />
              {validation.touched.slug && validation.errors.slug ? (
                <FormFeedback type="invalid">
                  {validation.errors.slug}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Id</Label>
              <Input
                name="id"
                type="text"
                placeholder="Opcional"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.id || ''}
                invalid={
                  validation.touched.id && validation.errors.id ? true : false
                }
              />
              {validation.touched.id && validation.errors.id ? (
                <FormFeedback type="invalid">
                  {validation.errors.id}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Região</Label>
              <Input
                name="region"
                type="text"
                placeholder="Região obrigatória"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.region || ''}
                invalid={
                  validation.touched.region && validation.errors.region
                    ? true
                    : false
                }
              />
              {validation.touched.region && validation.errors.region ? (
                <FormFeedback type="invalid">
                  {validation.errors.region}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Comissão</Label>
              <Input
                name="comission"
                type="text"
                placeholder="Comissão obrigatória"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.comission || ''}
                invalid={
                  validation.touched.comission && validation.errors.comission
                    ? true
                    : false
                }
              />
              {validation.touched.comission && validation.errors.comission ? (
                <FormFeedback type="invalid">
                  {validation.errors.comission}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
    </TabPane>
  )
}

export const ProfileStep = forwardRef(ProfileStepElement)
