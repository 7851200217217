import '@vtaits/react-color-picker/dist/index.css'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { ServerSidePagination } from 'components/Common/ServerSidePagination'
import 'flatpickr/dist/themes/material_blue.css'
import useDebounce from 'hooks/useDebounce'
import { useHttp } from 'hooks/useHttp'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Search } from 'react-bootstrap-table2-toolkit'
import MetaTags from 'react-meta-tags'
import { useQueryClient } from 'react-query'
import { useSortBy, useTable } from 'react-table'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import { Filters } from './components/Filters'
import { fetchAwards } from './http/fetchAwards'

export function Awards() {
  const { SearchBar } = Search
  const [currentPage, setCurrentPage] = useState(1)
  const queryClient = useQueryClient()

  const filterDatePickerRef = useRef(null)
  const [filters, setFilters] = useState({})
  const [searchFilterValue, setSearchFilterValue] = useState('')
  const debouncedFilter = useDebounce(searchFilterValue, 700)

  const { data, isSuccess, isLoading, isFetching } = useHttp(
    ['awards', currentPage, filters, debouncedFilter],
    () => fetchAwards(currentPage, filters, debouncedFilter),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (data?.next_page_url) {
      queryClient.prefetchQuery(
        ['awards', currentPage + 1, filters, debouncedFilter],
        () => fetchAwards(currentPage + 1, filters, debouncedFilter)
      )
    }
  }, [data, currentPage, queryClient, filters, debouncedFilter])

  const awards = useMemo(() => {
    return isSuccess ? data.data : []
  }, [isSuccess, data])

  const awardStatusVariants = {
    pago: {
      label: 'Pago',
      icon: '',
      color: 'text-success',
      dropdownActions: [
        { label: 'Estornar', icon: 'bx bx-rotate-left', color: 'text-warning' }
      ]
    },
    pendente: {
      label: 'Pendente',
      color: 'text-warning',
      dropdownActions: [
        { label: 'Pagar', icon: 'bx bx-comment-check', color: 'text-success' },
        { label: 'Reter', icon: 'bx bx-block', color: 'text-danger' }
      ]
    },
    retido: {
      label: 'Retido',
      icon: '',
      color: 'text-danger'
    }
  }

  const columns = useMemo(() => {
    return [
      {
        accessor: 'data_loteria',
        Header: 'Data Lot.'
      },
      {
        accessor: 'id_unidade',
        Header: 'Id unidade'
      },
      {
        accessor: 'alias',
        Header: 'Unidade'
      },
      {
        accessor: 'banca',
        Header: 'Banca'
      },
      {
        accessor: 'id_pule',
        Header: 'Pule'
      },
      {
        accessor: 'codigo_loteria',
        Header: 'Loteria'
      },
      {
        accessor: 'codigo_jogo',
        Header: 'Modalidade',
        width: 'auto'
      },
      {
        accessor: 'numero',
        Header: 'Número'
      },
      {
        accessor: 'posicao',
        Header: 'Colocação'
      },
      {
        accessor: 'valor',
        Header: 'Valor',
        Header: () => <div className="text-right">Valor</div>,
        Cell: ({ value }) => <div className="text-right">{value}</div>
      },
      {
        accessor: 'valor_premio',
        Header: 'Valor prêmio',
        Header: () => <div className="text-right">Valor prêmio</div>,
        Cell: ({ value }) => <div className="text-right">{value}</div>
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ value }) => {
          const currentStatus = awardStatusVariants[value]

          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="card-drop cursor-pointer d-flex align-center"
                  tag="div"
                >
                  <span
                    className={`font-size-14 ${currentStatus.color} text-normal`}
                  >
                    {currentStatus.label}
                  </span>
                  {currentStatus.label !== 'Retido' && (
                    <i
                      className={`bx bx-chevron-down font-size-16 ${currentStatus.color} me-1`}
                    />
                  )}
                </DropdownToggle>

                {currentStatus.label !== 'Retido' && (
                  <DropdownMenu className="dropdown-menu-end">
                    <>
                      {currentStatus.dropdownActions.map(action => (
                        <DropdownItem
                          key={action.label}
                          onClick={() => {}}
                          className={`d-flex align-center ${action.color}`}
                        >
                          <i className={`${action.icon} font-size-16 me-1`} />
                          {action.label}
                        </DropdownItem>
                      ))}
                    </>
                  </DropdownMenu>
                )}
              </UncontrolledDropdown>
            </>
          )
        }
      }
    ]
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data: awards
      },
      useSortBy
    )

  function handleCleanFilters() {
    setFilters({})
    filterDatePickerRef.current.value = ''

    if (searchFilterValue) {
      setSearchFilterValue('')
    }
  }

  const handleChangeFilters = useCallback((key, filter) => {
    const newFilters = {
      [key]: filter
    }

    switch (key) {
      case 'client':
        newFilters['client_id'] = filter.id
        break

      case 'lottery':
        newFilters['lotteryCode'] = filter.codigo_loteria
        break

      case 'period':
        newFilters['dates'] = filter.dates
    }

    setFilters(currentFilters => ({ ...currentFilters, ...newFilters }))
  }, [])

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Premiadas" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">Premiadas </CardTitle>
                      <p className="card-title-desc">Tabela de premiações</p>
                    </Col>
                    <Col lg="2">
                      <div className="d-flex justify-end">
                        {isFetching && <Spinner size="sm" />}
                        <span
                          className="ml-12 cursor-pointer"
                          onClick={handleCleanFilters}
                        >
                          Limpar filtros
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Buscar"
                            onSearch={value => setSearchFilterValue(value)}
                            searchText={searchFilterValue}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Filters
                    onChangeFilters={handleChangeFilters}
                    ref={filterDatePickerRef}
                    filters={filters}
                  />

                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <th
                                key={index}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {isLoading || isFetching ? (
                        <div className="mh-20 border-none">
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {rows.map((row, index) => {
                            prepareRow(row)
                            return (
                              <tr key={index} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex align-center">
                    <span>Total de {data?.total} registros</span>
                    <ServerSidePagination
                      canNextPage={data?.next_page_url}
                      canPreviousPage={data?.prev_page_url}
                      onNextPage={() =>
                        setCurrentPage(currentPage => currentPage + 1)
                      }
                      onPreviousPage={() =>
                        setCurrentPage(currentPage => currentPage - 1)
                      }
                      onChangePage={page => setCurrentPage(page)}
                      registersPerPage={data?.per_page}
                      totalPageCount={data?.total}
                      currentPage={currentPage}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
