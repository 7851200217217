import React from 'react'
import {
  Pagination as PaginationContainer,
  PaginationItem,
  PaginationLink
} from 'reactstrap'
import '../../assets/scss/custom.scss'

function generatePagesArray(from, to) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1
    })
    .filter(page => page > 0)
}

const siblingsCount = 1

export function ServerSidePagination({
  onChangePage,
  onNextPage,
  onPreviousPage,
  currentPage,
  totalPageCount,
  registersPerPage,
  canNextPage,
  canPreviousPage
}) {
  const lastPage = Math.ceil(totalPageCount / registersPerPage)

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  return (
    <div className="inner-custom-pagination d-flex col mt-2">
      <div className="ml-auto">
        <PaginationContainer>
          {canPreviousPage && (
            <>
              <PaginationItem onClick={onPreviousPage}>
                <PaginationLink href="#">
                  <i className="mdi mdi-chevron-left font-size-12" />
                </PaginationLink>
              </PaginationItem>
            </>
          )}

          {currentPage > 1 + siblingsCount - 1 && (
            <>
              <PaginationItem onClick={() => onChangePage(1)}>
                <PaginationLink href="#">1</PaginationLink>
              </PaginationItem>
            </>
          )}

          {previousPages.length > 0 ||
            previousPages.map(page => {
              return (
                <PaginationItem key={page} onClick={() => onChangePage(page)}>
                  <PaginationLink href="#" previous>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              )
            })}

          <PaginationItem className="active" onClick={onChangePage}>
            <PaginationLink href="#">{currentPage}</PaginationLink>
          </PaginationItem>

          {nextPages.length > 0 &&
            nextPages.map(page => {
              return (
                <PaginationItem key={page} onClick={() => onChangePage(page)}>
                  <PaginationLink>{page}</PaginationLink>
                </PaginationItem>
              )
            })}

          {canNextPage && (
            <>
              <PaginationItem onClick={onNextPage}>
                <PaginationLink href="#">
                  <i className="mdi mdi-chevron-right font-size-12" />
                </PaginationLink>
              </PaginationItem>
            </>
          )}
        </PaginationContainer>
      </div>
    </div>
  )
}
