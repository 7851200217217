import { useFormik } from 'formik'
import { ConfigsForm } from 'pages/Clients/components/ConfigsForm'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { TabPane } from 'reactstrap'

const ConfigsStepElement = ({ onToggleTab }, ref) => {
  const isFirstRender = useRef(true)
  const [paramsPayload, setParamsPayload] = useState({
    lti_enabled: 1,
    pp: 0,
    consulta_pule: 1,
    premios_reter: 1,
    webpassword: '',
    foot_enabled: 1,
    mbrinde: {
      enabled: 1
    }
  })
  const [filledValues, setFilledValues] = useState({})
  const [footQuotationType, setFootQuotationType] = useState({
    label: 'Acima',
    value: 'Acima'
  })

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return {
          ...paramsPayload,
          ...filledValues,
          footQuotationType
        }
      }
    }
  })

  const validation = useFormik({
    initialValues: {
      header_title: 'LOTERIAS',
      msg_apostas:
        'Confira sua aposta. Aposta valida por 5 dias.<br>Boa Sorte!',
      reclame_dias: '3',
      limite_jogo: '0,00',
      pule_cancela: '10',
      bg: '#f3f6fa',
      c1: '#1b98e0',
      c2: '#0e5581'
    }
  })

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    setFilledValues(validation.values)
  }, [onToggleTab])

  const handleChangeFootQuotationType = useCallback(type => {
    setFootQuotationType(type)
  }, [])

  const handleAddListed = useCallback(
    listed => {
      if (!paramsPayload.hasOwnProperty('cotadas')) {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [listed]
        }))
      } else if (!paramsPayload.hasOwnProperty('cotadas')) {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [...params.cotadas, listed]
        }))
      } else {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [...currentState.cotadas, listed]
        }))
      }
    },
    [paramsPayload]
  )

  const handleChangeParams = useCallback(
    paramKey => {
      let newParams = {}

      if (paramKey === 'mbrinde_enabled') {
        newParams['mbrinde'] = {
          enabled: Number(paramsPayload['mbrinde']?.enabled) === 1 ? '0' : '1'
        }
      } else {
        newParams[paramKey] = Number(paramsPayload[paramKey]) === 1 ? '0' : '1'
      }

      setParamsPayload(currentState => {
        return { ...currentState, ...newParams }
      })
    },
    [paramsPayload]
  )

  const handleChangeWebPassword = useCallback(value => {
    setParamsPayload(currentState => ({ ...currentState, webpassword: value }))
  }, [])

  const handleChangeThousandToast = useCallback(
    (_, value) => {
      const newParams = {
        mbrinde: {
          ...paramsPayload?.mbrinde,
          valor: value
        }
      }

      setParamsPayload(currentState => {
        return { ...currentState, ...newParams }
      })
    },
    [paramsPayload]
  )

  return (
    <TabPane tabId={3}>
      <div>
        <ConfigsForm
          validation={validation}
          onAddListed={handleAddListed}
          onChangeParams={handleChangeParams}
          onChangeThousandToast={handleChangeThousandToast}
          onChangeFootQuotationType={handleChangeFootQuotationType}
          footQuotationType={footQuotationType}
          params={paramsPayload}
          isSaving={false}
          shouldShowSaveButton={false}
          includeWebAccess
          onChangeWebPassword={handleChangeWebPassword}
        />
      </div>
    </TabPane>
  )
}

export const ConfigsStep = forwardRef(ConfigsStepElement)
