import { fetchClients } from 'core/http/fetchClients'
import { fetchRegions } from 'core/http/fetchRegions'
import { useHttp } from 'hooks/useHttp'
import React, { forwardRef } from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'

export const FiltersComponent = (
  { onChangeFilters, filters, shouldFetchFilters },
  ref
) => {
  const { data: regions, isLoading: isRegionsLoading } = useHttp(
    ['regions'],
    fetchRegions,
    {
      enabled: shouldFetchFilters,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      select: regions => {
        if (filters.client) {
          return regions.filter(
            region => region.id_unidade === filters.client.id
          )
        }

        return regions
      }
    }
  )

  const { data: clients, isLoading: isClientsLoading } = useHttp(
    'clients',
    fetchClients,
    {
      enabled: shouldFetchFilters,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  )

  return (
    <Row>
      <Col lg="4">
        <div className="mb-3">
          <label className="control-label">Cliente</label>
          <Select
            value={filters?.client || [{ label: 'Cliente', value: 'all' }]}
            onChange={value => onChangeFilters('client', value)}
            placeholder="Selecionar cliente"
            options={
              isClientsLoading
                ? [{ label: 'Carregando...', value: 'Carregando...' }]
                : clients
                ? [{ label: 'Cliente', value: 'all' }, ...clients]
                : []
            }
          />
        </div>
      </Col>
      <Col lg="4">
        <div className="mb-3">
          <label className="control-label">Região</label>
          <Select
            value={filters?.region || [{ label: 'Região', value: 'all' }]}
            onChange={value => onChangeFilters('region', value)}
            placeholder="Selecionar loteria"
            options={
              isRegionsLoading
                ? [{ label: 'Carregando...', value: 'Carregando...' }]
                : regions
                ? [{ label: 'Região', value: 'all' }, ...regions]
                : []
            }
          />
        </div>
      </Col>
    </Row>
  )
}

export const Filters = forwardRef(FiltersComponent)
