import { currencyFormat } from 'helpers/currencyFormat'
import { api } from 'services/api'

export async function fetchPaymentTb(params = null) {
  const { data } = await api.get('/paymentTb')

  const newTables = data.map(table => {
    const newValuesPgto = {}

    Object.entries(table.valores_pgto).forEach(([key, values]) => {
      if (key !== '') {
        const valuesAreEqual = values.every(value => {
          if (value.pagamento.valor === values[0].pagamento.valor) {
            return true
          }
        })

        newValuesPgto[key] = {
          globalValue: valuesAreEqual
            ? currencyFormat(values[0].pagamento.valor)
            : '0,00',
          values: values.map(value => {
            return {
              ...value,
              pagamento: {
                ...value.pagamento,
                valor: currencyFormat(value.pagamento.valor)
              }
            }
          })
        }
      }
    })

    return {
      ...table,
      valores_pgto: newValuesPgto
    }
  })

  return newTables
}
