import { api } from '../../services/api'

export async function fetchProfiles({ forSelectInput = true }) {
  const { data } = await api.get('/profiles')

  if (forSelectInput) {
    return data.map(profile => ({
      ...profile,
      label: profile.profile,
      value: profile.profile
    }))
  }

  return data
}
