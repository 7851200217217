import classnames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Collapse } from 'reactstrap'

const LotteryGroupCollapseItemElement = ({
  group,
  lotteries: lotteriesInput,
  onChangeLotteries
}) => {
  const isFirstRender = useRef(true)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [lotteries, setLotteries] = useState(lotteriesInput)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    onChangeLotteries(group, lotteries)
  }, [lotteries])

  const isGroupChecked = useMemo(() => {
    return lotteries.some(lottery => lottery.active === 1)
  }, [lotteries])

  function handleChangeLotteryGroup(e) {
    e.stopPropagation()

    const newLotteries = lotteries.map(lottery => ({
      ...lottery,
      active: isGroupChecked ? 0 : 1
    }))

    setLotteries(newLotteries)
  }

  function handleChangeLottery(lotteryInput) {
    const newLotteries = lotteries.map(lottery => {
      if (lotteryInput.codigo_loteria === lottery.codigo_loteria) {
        return {
          ...lottery,
          active: lottery.active === 1 ? 0 : 1
        }
      }

      return lottery
    })

    setLotteries(newLotteries)
  }

  return (
    <>
      <div className="accordion-item border-none">
        <h2 className="accordion-header" id="headingFlushOne">
          <button
            className={classnames(
              'accordion-button',
              'fw-medium',
              'bg-white',
              'text-black',
              'shadow-none',
              'ph-0',
              {
                collapsed: isCollapsed
              }
            )}
            type="button"
            onClick={() => setIsCollapsed(currentState => !currentState)}
            style={{ cursor: 'pointer' }}
          >
            <input
              className="form-check-input mr-8 mt-0"
              type="checkbox"
              checked={isGroupChecked}
              id="groupCheck"
              onChange={() => {}}
              onClick={handleChangeLotteryGroup}
            />
            {group}
          </button>
        </h2>
        <Collapse isOpen={isCollapsed} className="accordion-collapse">
          <div className="accordion-body d-flex flex-column border-none pv-0">
            {lotteries.map((lottery, index) => (
              <div key={index} className="w-full d-flex align-center">
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={lottery.active}
                    id="lotteryCheck"
                    onChange={() => {}}
                    onClick={() => handleChangeLottery(lottery)}
                  />
                  <label
                    className="form-check-label ml-6"
                    htmlFor="lotteryCheck"
                  >
                    {lottery.alias}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </>
  )
}

export const LotteryGroupCollapseItem = LotteryGroupCollapseItemElement
