import React from 'react'
//i18n
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import SidebarContent from './SidebarContent'

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-lg">
              <img src={logo} alt="" height="24" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout
  }
}

export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
