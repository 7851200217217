import { api } from 'services/api'

export async function fetchLotteryGroups() {
  const { data } = await api.get('/lotteryGroups')

  return Object.entries(data).map(([group, lotteries]) => {
    return {
      label: group,
      options: lotteries
        .map(lottery => ({
          ...lottery,
          label: lottery.alias,
          value: lottery.alias
        }))
        .sort((firstLottery, secondLottery) => {
          if (firstLottery.indice < secondLottery.indice) {
            return -1
          }
          if (firstLottery.indice > secondLottery.indice) {
            return 1
          }

          return 0
        })
    }
  })
}
