import { Toast } from 'components/Common/Toast'
import { useFormik } from 'formik'
import { createUser } from 'pages/Users/http/createUser'
import React, { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import * as Yup from 'yup'
import { InformationsSection } from './sections/InformationsSection'
import { PermissionsSection } from './sections/PermissionsSection'

const DEFAULT_PERMISSIONS = {
  cadastra_resultado: 0,
  cadastra_financeiro: 1,
  cadastra_lancamento: 1,
  painel_financeiro: 1,
  painel_dispositivos: 1,
  painel_resultados: 1,
  painel_cobrancas: 1,
  painel_saldo: 1,
  painel_pules: 1
}

export function NewUserModal({ isOpen, onToggle }) {
  const [paramsErrors, setParamsErrors] = useState({})
  const [permissionsPayload, setPermissionsPayload] =
    useState(DEFAULT_PERMISSIONS)
  const [isCreatingUser, setIsCreatingUser] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [selectedRegions, setSelectedRegions] = useState([])
  const [selectedCollector, setSelectedCollector] = useState(null)

  const queryClient = useQueryClient()

  const notifySuccess = () => {
    toast.success('Usuário criado com sucesso!')
  }

  const notifyError = () => {
    toast.error('Erro ao criar usuário')
  }

  const handleChangePermissions = useCallback(
    key => {
      const newPermissions = {
        ...permissionsPayload,
        [key]: permissionsPayload[key] === 1 ? 0 : 1
      }

      setPermissionsPayload(newPermissions)
    },
    [permissionsPayload]
  )

  const handleChangeParams = useCallback(
    (key, value) => {
      switch (key) {
        case 'client':
          setSelectedClient(value)
          break

        case 'profile':
          setSelectedProfile(value)
          if (value.value !== 'Cobrador ' && selectedCollector) {
            setSelectedCollector(null)
          } else if (value.value !== 'Gerente Regiao ' && selectedRegions) {
            setSelectedRegions(null)
          }
          break

        case 'regions':
          setSelectedRegions(value)
          break

        case 'collector':
          setSelectedCollector(value)
          break
      }

      if (paramsErrors[key]) {
        setParamsErrors({})
      }
    },
    [selectedRegions, selectedCollector, paramsErrors]
  )

  const params = useMemo(() => {
    return {
      client: selectedClient,
      profile: selectedProfile,
      regions: selectedRegions,
      collector: selectedCollector
    }
  }, [selectedClient, selectedProfile, selectedRegions, selectedCollector])

  async function handleCreateUser({ name, email, password }) {
    setIsCreatingUser(true)

    if (!!params.client === false) {
      setParamsErrors({
        client: {
          message: 'Selecione a banca'
        }
      })
      return
    }

    if (!!params.client === true && !!params.profile === false) {
      setParamsErrors({
        profile: {
          message: 'Selecione o perfil'
        }
      })
      return
    }

    if (!!params.profile === true) {
      if (
        params.profile.value === 'Cobrador ' &&
        !!params.collector === false
      ) {
        setParamsErrors({
          collector: {
            message: 'Selecione o cobrador'
          }
        })

        return
      }

      if (
        params.profile.value === 'Gerente Regiao' &&
        params.regions.length === 0
      ) {
        setParamsErrors({
          region: {
            message: 'Selecione a região'
          }
        })

        return
      }
    }

    try {
      const newUser = await createUser({
        name,
        email,
        password,
        profile: params.profile.profile,
        id_parent: params.client.id,
        regions: params.regions
          ? params.regions.map(region => region.id)
          : null,
        cobrador_id: params.collector?.id,
        permissions: permissionsPayload
      })

      queryClient.setQueryData('users', currentData => {
        return [
          ...currentData,
          {
            ...newUser,
            user_id: newUser.id,
            alias: params.client.alias,
            profile: params.profile.profile,
            cobrador_alias: params.collector?.value,
            cobrador_id: params.collector?.id
          }
        ]
      })

      onCloseModal()
      notifySuccess()
    } catch (err) {
      notifyError()
    }

    setIsCreatingUser(false)
  }

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Nome obrigatório'),
      email: Yup.string().required('E-mail obrigatório'),
      password: Yup.string().required('Senha obrigatória')
    }),
    initialValues: {
      name: '',
      email: '',
      password: ''
    },
    onSubmit: values => {
      handleCreateUser(values, validation)
    }
  })

  function onCloseModal() {
    setSelectedClient(null)
    setSelectedProfile(null)
    setSelectedCollector(null)
    setSelectedRegions([])
    setParamsErrors({})
    setPermissionsPayload(DEFAULT_PERMISSIONS)
    validation.resetForm()
    onToggle()
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={onCloseModal} size="lg">
        <ModalHeader toggle={onCloseModal} tag="h4">
          Novo usuário
        </ModalHeader>
        <ModalBody>
          <InformationsSection
            validation={validation}
            onChangeParams={handleChangeParams}
            params={params}
            errors={paramsErrors}
          />
          <PermissionsSection
            permissions={permissionsPayload}
            onChangePermissions={handleChangePermissions}
          />
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={() => validation.handleSubmit()}
                >
                  {isCreatingUser && (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  )}
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
