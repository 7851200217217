export const stepsContent = [
  {
    step: 1,
    label: 'Perfil'
  },
  {
    step: 2,
    label: 'Pagamento e Loterias'
  },
  {
    step: 3,
    label: 'Configurações'
  },
  {
    step: 4,
    label: 'Usuário'
  }
]
