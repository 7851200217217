import React from 'react'
import Dropzone from 'react-dropzone'
import { CardTitle, Col, FormFeedback, Input, Label } from 'reactstrap'

export function ThemeSection({ validation }) {
  return (
    <>
      <CardTitle className="mb-4 mt-4">Tema</CardTitle>
      <Col lg="4">
        <Dropzone onDrop={() => {}}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Logo.</h4>
              </div>
            </div>
          )}
        </Dropzone>
      </Col>
      <Col lg="8">
        <div className="mb-3">
          <Label className="form-label">Cor de fundo</Label>
          <Input
            name="bg"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.bg || ''}
            invalid={
              validation.touched.bg && validation.errors.bg ? true : false
            }
          />
          {validation.touched.bg && validation.errors.bg ? (
            <FormFeedback type="invalid">{validation.errors.bg}</FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Cor primária</Label>
          <Input
            name="c1"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.c1 || ''}
            invalid={
              validation.touched.c1 && validation.errors.c1 ? true : false
            }
          />
          {validation.touched.c1 && validation.errors.c1 ? (
            <FormFeedback type="invalid">{validation.errors.c1}</FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Cor secundária</Label>
          <Input
            name="c2"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.c2 || ''}
            invalid={
              validation.touched.c2 && validation.errors.c2 ? true : false
            }
          />
          {validation.touched.c2 && validation.errors.c2 ? (
            <FormFeedback type="invalid">{validation.errors.c2}</FormFeedback>
          ) : null}
        </div>

        <div className="">
          <Label className="form-label">Nome de exibição</Label>
          <Input
            name="exhibitionName"
            type="numeric"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.exhibitionName || ''}
            invalid={
              validation.touched.exhibitionName &&
              validation.errors.exhibitionName
                ? true
                : false
            }
          />
          {validation.touched.exhibitionName &&
          validation.errors.exhibitionName ? (
            <FormFeedback type="invalid">
              {validation.errors.exhibitionName}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </>
  )
}
