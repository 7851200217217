import { format } from 'date-fns'
import { api } from 'services/api'
import { currencyFormat } from '../../../helpers/currencyFormat'

export async function fetchAwards(page, filters, searchFilter) {
  const { data } = await api.get(`/awards?page=${page}`, {
    params: {
      searchFilter,
      client_id: filters?.client_id,
      lotteryCode: filters?.lotteryCode,
      dates: filters?.dates
    }
  })

  const awards = data.data.map(award => {
    return {
      ...award,
      data_loteria: format(new Date(award.data_loteria), 'dd/MM'),
      valor: currencyFormat(award.valor),
      valor_premio: currencyFormat(award.valor_premio)
    }
  })

  return {
    ...data,
    data: awards
  }
}
