import { Toast } from 'components/Common/Toast'
import { fetchRegions } from 'core/http/fetchRegions'
import { useFormik } from 'formik'
import { createUser } from 'pages/Users/http/createUser'
import { updateUser } from 'pages/Users/http/updateUser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import * as Yup from 'yup'
import { prepareEditRequestPayload } from '../utils/prepareEditRequestPayload'
import { validateUser } from '../utils/validateUser'
import { InformationsSection } from './sections/InformationsSection'
import { PermissionsSection } from './sections/PermissionsSection'

export function EditUserModal({ isOpen, onToggle, user }) {
  const [paramsErrors, setParamsErrors] = useState({})
  const [permissionsPayload, setPermissionsPayload] = useState({})

  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [selectedRegions, setSelectedRegions] = useState([])
  const [selectedCollector, setSelectedCollector] = useState(null)

  const [isUpdatingUser, setIsUpdatingUser] = useState(false)

  const queryClient = useQueryClient()

  const informationsSectionRef = useRef(null)

  const {
    data: regions,
    isFetching: isRegionsFetching,
    isLoading: isRegionsLoading
  } = useQuery(
    ['regions', selectedClient],
    () =>
      fetchRegions({
        id_parent: selectedClient?.id
      }),
    {
      enabled: !!selectedClient && selectedProfile?.value === 'Gerente Regiao',
      refetchOnWindowFocus: false
    }
  )

  const INITIAL_PERMISSIONS = useMemo(() => {
    return {
      cadastra_resultado: user?.cadastra_resultado,
      cadastra_financeiro: user?.cadastra_financeiro,
      cadastra_lancamento: user?.cadastra_lancamento,
      painel_financeiro: user?.painel_financeiro,
      painel_dispositivos: user?.painel_dispositivos,
      painel_resultados: Number(user?.painel_resultados),
      painel_cobrancas: user?.painel_cobrancas,
      painel_saldo: user?.painel_saldo,
      painel_pules: user?.painel_pules
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const userInitialPermissions = INITIAL_PERMISSIONS
      setPermissionsPayload(userInitialPermissions)

      setSelectedClient({
        value: user.alias,
        label: user.alias,
        id: user.id_parent
      })

      setSelectedProfile({
        value: user.profile,
        label: user.profile
      })

      if (user.profile === 'Cobrador ') {
        setSelectedCollector({
          value: user.cobrador_alias,
          label: user.cobrador_alias,
          id: user.cobrador_id
        })
      }
    }
  }, [user])

  useEffect(() => {
    if (user && !!regions && selectedProfile.value === 'Gerente Regiao') {
      if (user.regiaos) {
        const userRegions = JSON.parse(user.regiaos)

        const selectedRegions = userRegions.map(userRegionId => {
          const [region] = regions.filter(
            region => Number(region.id) === Number(userRegionId)
          )

          return region
        })

        setSelectedRegions(selectedRegions)
      } else {
        setSelectedRegions([])
      }
    }
  }, [regions, selectedProfile])

  const params = useMemo(() => {
    return {
      client: selectedClient,
      profile: selectedProfile,
      regions: selectedRegions,
      collector: selectedCollector
    }
  }, [selectedClient, selectedProfile, selectedRegions, selectedCollector])

  const notifySuccess = () => {
    const toastMsg = user.shouldCloneUser
      ? 'Usuário criado com sucesso'
      : 'Usuário editado com sucesso!'

    toast.success(toastMsg)
  }

  const notifyError = () => {
    const toastMsg = user.shouldCloneUser
      ? 'Erro ao criar usuário'
      : 'Erro ao editar usuário'

    toast.error(toastMsg)
  }

  function onCloseModal() {
    setSelectedClient(null)
    setSelectedProfile(null)
    setSelectedCollector(null)
    setSelectedRegions([])
    setParamsErrors({})
    setPermissionsPayload(INITIAL_PERMISSIONS)
    validation.resetForm()
    onToggle()
  }

  const handleChangePermissions = useCallback(
    key => {
      const newPermissions = {
        ...permissionsPayload,
        [key]: permissionsPayload[key] === 1 ? 0 : 1
      }

      setPermissionsPayload(newPermissions)
    },
    [permissionsPayload]
  )

  const handleChangeParams = useCallback(
    (key, value) => {
      switch (key) {
        case 'client':
          setSelectedClient(value)
          break

        case 'profile':
          setSelectedProfile(value)
          if (value.value !== 'Cobrador ' && selectedCollector) {
            setSelectedCollector(null)
          } else if (value.value !== 'Gerente Regiao ' && selectedRegions) {
            setSelectedRegions(null)
          }
          break

        case 'regions':
          setSelectedRegions(value)
          break

        case 'collector':
          setSelectedCollector(value)
          break
      }

      if (paramsErrors[key]) {
        setParamsErrors({})
      }
    },
    [selectedRegions, selectedCollector, paramsErrors]
  )

  async function handleUpdateUser({ name, email, password }) {
    const errors = validateUser(params)

    if (!!Object.keys(errors).length) {
      setParamsErrors(errors)

      return
    }

    setIsUpdatingUser(true)

    try {
      if (user.shouldCloneUser) {
        const newUser = await createUser({
          name,
          email,
          password,
          profile: params.profile.value,
          id_parent: params.client.id,
          regions: params.regions
            ? params.regions.map(region => region.id)
            : null,
          cobrador_id: params.collector?.id,
          permissions: permissionsPayload
        })

        queryClient.setQueryData('users', currentData => {
          return [
            ...currentData,
            {
              ...newUser,
              user_id: newUser.id,
              alias: params.client.value,
              profile: params.profile.value,
              cobrador_alias: params.collector?.value,
              cobrador_id: params.collector?.id
            }
          ]
        })
      } else {
        const requestPayload = prepareEditRequestPayload({
          INITIAL_PERMISSIONS,
          permissionsPayload,
          params,
          user,
          name,
          email,
          password
        })

        const updatedUser = await updateUser(requestPayload)

        const newUser = {
          ...updatedUser,
          user_id: updatedUser.id,
          alias: params?.client.value ?? user.alias,
          profile: params?.profile.value ?? user.profile,
          cobrador_alias: params.collector?.value,
          cobrador_id: params.collector?.id
        }

        queryClient.setQueryData('users', currentData => {
          return currentData.map(user => {
            if (user.user_id === newUser.user_id) {
              return newUser
            }

            return user
          })
        })
      }

      onCloseModal()
      notifySuccess()
    } catch (err) {
      notifyError()
    }

    setIsUpdatingUser(false)
  }

  const validationSchema = user?.shouldCloneUser
    ? Yup.object({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string().required('E-mail obrigatório'),
        password: Yup.string().required('Senha obrigatória')
      })
    : Yup.object()

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: user?.name,
      email: user?.email,
      password: ''
    },
    onSubmit: values => {
      handleUpdateUser(values)
    }
  })

  return (
    <>
      <Modal isOpen={isOpen} toggle={onCloseModal} size="lg">
        <ModalHeader toggle={onCloseModal} tag="h4">
          {user?.shouldCloneUser ? 'Duplicar usuário' : 'Editar usuário'}
        </ModalHeader>
        <ModalBody>
          <InformationsSection
            ref={informationsSectionRef}
            validation={validation}
            onChangeParams={handleChangeParams}
            params={params}
            errors={paramsErrors}
            regions={regions}
            isRegionsFetching={isRegionsFetching}
            isRegionsLoading={isRegionsLoading}
          />
          <PermissionsSection
            permissions={permissionsPayload}
            onChangePermissions={handleChangePermissions}
          />
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={() => validation.handleSubmit()}
                >
                  {isUpdatingUser && (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  )}
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
