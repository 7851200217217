import classnames from 'classnames'
import { Toast } from 'components/Common/Toast'
import { formatCurrencyToApi } from 'helpers/formatCurrencyToApi'
import { removeEmptyValues } from 'helpers/removeEmptyValues'
import React, { useCallback, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent
} from 'reactstrap'
import { createClient } from '../../http/createClient'
import { stepsContent } from './steps.content'
import { ConfigsStep } from './steps/ConfigsStep'
import { LotteryPaymentStep } from './steps/LotteryPaymentStep'
import { ProfileStep } from './steps/ProfileStep'
import { UserStep } from './steps/UserStep'

export function NewClientModal({ isOpen, onToggle }) {
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [isSavingClient, setIsSavingClient] = useState(false)

  const queryClient = useQueryClient()

  const tabsContentRef = useRef(null)
  const profileStepRef = useRef(null)
  const lotteryPaymentStepRef = useRef(null)
  const configsStepRef = useRef(null)
  const userStepRef = useRef(null)

  const notifySuccess = () => {
    toast.success('Novo cliente cadastrado com sucesso!')
  }

  const notifyError = () => {
    toast.error('Erro ao cadastrar novo cliente')
  }

  async function handleSaveClient() {
    setIsSavingClient(true)

    let values = {}

    tabsContentRef.current.props.children.map(step => {
      values = { ...values, ...step.ref.current.getValues() }
    })

    const ids_loterias = []

    values.lotteryGroups.forEach(lotteryGroup => {
      lotteryGroup.value.forEach(lottery => {
        ids_loterias.push(lottery.codigo_loteria)
      })
    })

    const paymentTables = []

    values.paymentTbs.forEach(paymentTb => {
      const valores_pgto = []

      Object.values(paymentTb.value.valores_pgto).forEach(value => {
        value.values.forEach(table => {
          valores_pgto.push({
            ...table.pagamento,
            valor: formatCurrencyToApi(table.pagamento.valor).replace('.00', '')
          })
        })
      })

      paymentTables.push({
        alias: paymentTb.label,
        valores_pgto
      })
    })

    const requestPayload = removeEmptyValues({
      ...values,
      alias: values.alias.toUpperCase(),
      limite_jogo: formatCurrencyToApi(values.limite_jogo) ?? '',
      foot_cotacao_valor: formatCurrencyToApi(values.foot_cotacao_valor) ?? '',
      ids_pagamentos: values.paymentTbs.map(paymentTb => ({
        id_tabela_pgto: String(paymentTb.value.id),
        comissao: String(values.comission)
      })),
      ids_loterias,
      paymentTables,
      params: {
        pp: String(values.pp),
        mbrinde: String(values.mbrinde.enabled)
      }
    })

    delete requestPayload.lotteryGroups
    delete requestPayload.paymentTbs

    try {
      await createClient(requestPayload)
      await queryClient.invalidateQueries(['clients'])

      notifySuccess()
      onToggle()
    } catch (err) {
      notifyError()
    }

    setIsSavingClient(false)
  }

  const toggleTab = useCallback(
    tab => {
      if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab]
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          setPassedSteps(modifiedSteps)
        }
      }
    },
    [passedSteps, activeTab]
  )

  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} size="xl">
        <ModalHeader toggle={onToggle} tag="h4">
          Novo cliente
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="wizard clearfix">
            <div className="steps clearfix">
              <ul>
                {stepsContent.map(step => (
                  <NavItem
                    key={step.step}
                    className={classnames({ current: activeTab === step.step })}
                  >
                    <NavLink
                      className={classnames({
                        current: activeTab === step.step
                      })}
                      onClick={() => {
                        setactiveTab(step.step)
                      }}
                      disabled={!(passedSteps || []).includes(step.step)}
                    >
                      <span className="number">0{step.step}</span> {step.label}
                    </NavLink>
                  </NavItem>
                ))}
              </ul>
            </div>
            <div className="content clearfix mt-4">
              <TabContent ref={tabsContentRef} activeTab={activeTab}>
                <ProfileStep ref={profileStepRef} onToggleTab={toggleTab} />
                <LotteryPaymentStep
                  ref={lotteryPaymentStepRef}
                  onToggleTab={toggleTab}
                />
                <ConfigsStep ref={configsStepRef} onToggleTab={toggleTab} />
                <UserStep ref={userStepRef} />
              </TabContent>
            </div>
            <div className="actions clearfix">
              <ul>
                <li
                  className={
                    activeTab === stepsContent[0]
                      ? 'previous disabled'
                      : 'previous'
                  }
                >
                  <Link
                    to="#"
                    onClick={() => {
                      toggleTab(activeTab - 1)
                    }}
                  >
                    Anterior
                  </Link>
                </li>
                <li>
                  {activeTab === stepsContent.length ? (
                    <Link
                      to="#"
                      className="bg-success"
                      onClick={() => {
                        handleSaveClient()
                      }}
                    >
                      {isSavingClient && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      Salvar
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={() => {
                        toggleTab(activeTab + 1)
                      }}
                    >
                      Próximo
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
