import { Toast } from 'components/Common/Toast'
import { fetchClients } from 'core/http/fetchClients'
import { fetchUnits } from 'core/http/fetchUnits'
import { useFormik } from 'formik'
import { capitalizeTitle } from 'helpers/capitalizeTitle'
import { useHttp } from 'hooks/useHttp'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { updateDevice } from '../http/updateDevice'

export function EditDeviceModal({ isOpen, onToggle, device }) {
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({})
  const [selectedClient, setSelectedClient] = useState(null)
  const [isEditingDevice, setIsEditingDevice] = useState(false)

  const queryClient = useQueryClient()

  const notifySuccess = () => {
    toast.success('Dispositivo alterado com sucesso')
  }

  const notifyError = () => {
    toast.error('Erro ao alterar dispositivo')
  }

  const { data: units, isLoading: isUnitsLoading } = useHttp(
    ['units'],
    fetchUnits,
    {
      refetchOnWindowFocus: false,
      enabled: !!device,
      select: units => {
        if (selectedClient) {
          return units.filter(unit => unit.id_parent === selectedClient.id)
        }

        return []
      }
    }
  )

  const { data: clients, isLoading: isClientsLoading } = useHttp(
    ['clients'],
    fetchClients,
    {
      enabled: !!device,
      refetchOnWindowFocus: false
    }
  )

  const statusOptions = useMemo(() => {
    if (device) {
      const defaultStatusOptions = [
        { label: 'Ativado', value: 'ativado' },
        { label: 'Desativado', value: 'desativado' }
      ]

      if (device?.status === 'pendente') {
        defaultStatusOptions.push({ label: 'Pendente', value: 'pendente' })
      }

      return defaultStatusOptions
    }
  }, [device])

  async function handleEditDevice(values) {
    setIsEditingDevice(true)

    if (!device.id_unidade) {
      return
    }

    try {
      const requestPayload = {
        id: device.id,
        data: {
          id_unidade: selectedUnit.id,
          status: selectedStatus.value,
          password: values.password,
          descricao: values.descricao
        }
      }

      await updateDevice(requestPayload)

      const newDevice = {
        ...requestPayload.data,
        unidade: selectedUnit.label,
        banca_alias: selectedClient.label
      }

      queryClient.setQueryData('devices', currentData => {
        return currentData.map(currentDevice => {
          if (currentDevice.id === requestPayload.id) {
            return {
              ...device,
              ...newDevice
            }
          }

          return currentDevice
        })
      })

      notifySuccess()
      onToggle()
    } catch (err) {
      console.log(err)
    }

    setIsEditingDevice(false)
  }

  useEffect(() => {
    if (device) {
      if (device.id_unidade) {
        const client = {
          label: device.banca_alias,
          value: device.banca_alias,
          id: device.id_parent
        }

        const unit = {
          label: device.unidade,
          value: device.unidade,
          id: device.id_unidade,
          id_parent: device.id_parent
        }

        setSelectedUnit(unit)
        setSelectedClient(client)
      }

      setSelectedStatus({
        label: capitalizeTitle(device.status),
        value: device.status
      })
    }
  }, [device])

  useEffect(() => {
    if (
      selectedClient &&
      selectedUnit &&
      selectedClient.id !== selectedUnit.id_parent
    ) {
      setSelectedUnit(null)
    }
  }, [selectedClient, selectedUnit])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: device?.password,
      descricao: device?.descricao
    },
    onSubmit: values => {
      handleEditDevice(values)
    }
  })

  return (
    <>
      <Modal isOpen={isOpen} toggle={onToggle} scrollable>
        <ModalHeader toggle={onToggle} tag="h4">
          Editar dispositivo
        </ModalHeader>
        <ModalBody>
          <Col>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row form>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">Banca</Label>
                    <Select
                      value={selectedClient || []}
                      onChange={value => setSelectedClient(value)}
                      placeholder="Selecionar cliente"
                      options={
                        isClientsLoading
                          ? [{ label: 'Carregando...', value: 'Carregando...' }]
                          : clients
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Unidade</Label>
                    <Select
                      value={selectedUnit || []}
                      onChange={value => setSelectedUnit(value)}
                      placeholder="Selecionar unidade"
                      options={
                        isUnitsLoading
                          ? [{ label: 'Carregando...', value: 'Carregando...' }]
                          : units
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Senha</Label>
                    <Input
                      name="password"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ''}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>

                    <Select
                      value={selectedStatus || []}
                      onChange={value => setSelectedStatus(value)}
                      placeholder="Selecionar cliente"
                      options={statusOptions}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Descrição</Label>
                    <Input
                      name="descricao"
                      type="text"
                      placeholder="Descrição aqui"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.descricao || ''}
                      invalid={
                        validation.touched.descricao &&
                        validation.errors.descricao
                          ? true
                          : false
                      }
                    />
                    {validation.touched.descricao &&
                    validation.errors.descricao ? (
                      <FormFeedback type="invalid">
                        {validation.errors.descricao}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      {isEditingDevice && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      Salvar
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </ModalBody>
      </Modal>

      <Toast />
    </>
  )
}
