import React from 'react'
import { CardTitle, Col, Row } from 'reactstrap'

export function PermissionsSection({ permissions, onChangePermissions }) {
  return (
    <Row>
      <Col lg="12">
        <CardTitle className="mb-4 mt-4">Permissões</CardTitle>
        <Row>
          <Col lg="4">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.cadastra_resultado === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('cadastra_resultado')}
              />
              <label className="form-check-label ml-6">
                Cadastra resultado
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.cadastra_financeiro === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('cadastra_financeiro')}
              />
              <label className="form-check-label ml-6">
                Cadastra financeiro
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.cadastra_lancamento === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('cadastra_lancamento')}
              />
              <label className="form-check-label ml-6">
                Cadastra lançamento
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_financeiro === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_financeiro')}
              />
              <label className="form-check-label ml-6">Painel financeiro</label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_dispositivos === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_dispositivos')}
              />
              <label className="form-check-label ml-6">
                Painel dispositivos
              </label>
            </div>
          </Col>
          <Col lg="3">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_resultados === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_resultados')}
              />
              <label className="form-check-label ml-6">Painel resultados</label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_cobrancas === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_cobrancas')}
              />
              <label className="form-check-label ml-6">Painel cobranças</label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_saldo === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_saldo')}
              />
              <label className="form-check-label ml-6">Painel saldo </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={permissions.painel_pules === 1}
                onChange={() => {}}
                onClick={() => onChangePermissions('painel_pules')}
              />
              <label className="form-check-label ml-6">Painel pules</label>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
