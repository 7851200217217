import { AuthProvider } from 'contexts/AuthContext'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { connect } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'
import './assets/scss/theme.scss'
import { Routes } from './routes'

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Routes />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  )
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, null)(App)
