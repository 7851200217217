import classnames from 'classnames'
import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input'
import { Col, Collapse } from 'reactstrap'

const TableGroupCollapseItemElement = ({
  group,
  values,
  globalValue,
  onChangeValues,
  onChangeGlobalValues
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <>
      <div className="accordion-item border-none">
        <h2 className="accordion-header" id="headingFlushOne">
          <button
            className={classnames(
              'accordion-button',
              'fw-medium',
              'bg-white',
              'text-black',
              'shadow-none',
              'ph-0',
              {
                collapsed: isCollapsed
              }
            )}
            type="button"
            onClick={() => setIsCollapsed(currentState => !currentState)}
            style={{ cursor: 'pointer' }}
          >
            {group}
          </button>

          <CurrencyInput
            type="money"
            inputMode="decimal"
            className="form-control input-color mb-3"
            placeholder="0,00"
            decimalSeparator=","
            thousandSeparator="."
            onChangeEvent={e => {
              onChangeGlobalValues(group, e.target.value)
            }}
            value={globalValue}
          />
        </h2>
        <Collapse isOpen={isCollapsed} className="accordion-collapse">
          <div className="accordion-body d-flex flex-column border-none p-0">
            <>
              {values.map((game, index) => (
                <div
                  key={index}
                  className="w-full d-flex align-center justify-between mb-3"
                >
                  <div>
                    <label className="form-check-label">
                      {`${game.pagamento.codigo_jogo} - ${game.pagamento.descricao}`}
                    </label>
                  </div>
                  <Col lg="4">
                    <CurrencyInput
                      name=""
                      type="money"
                      inputMode="decimal"
                      className="form-control input-color text-right"
                      placeholder="0,00"
                      decimalSeparator=","
                      thousandSeparator="."
                      onChangeEvent={e => onChangeValues(game, e.target.value)}
                      value={game.pagamento.valor || ''}
                    />
                  </Col>
                </div>
              ))}
            </>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export const TableGroupCollapseItem = TableGroupCollapseItemElement
