import React from 'react'
import { Col, Form, Row } from 'reactstrap'
import { InformationsSection } from './sections/InformationsSection'
import { ListedsSection } from './sections/ListedsSection'
import { ParamsSection } from './sections/ParamsSection'
import { ThemeSection } from './sections/ThemeSection'

export function ConfigsForm({
  validation,
  isSaving,
  params,
  onAddListed,
  onChangeParams,
  onChangeThousandToast,
  onChangeFootQuotationType,
  footQuotationType,
  shouldShowSaveButton = true,
  includeWebAccess = false,
  onChangeWebPassword = () => null
}) {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      <Row>
        <InformationsSection
          validation={validation}
          onChangeFootQuotationType={onChangeFootQuotationType}
          footQuotationType={footQuotationType}
        />
        <ThemeSection validation={validation} />
        <ListedsSection params={params} onAddListed={onAddListed} />
        <ParamsSection
          params={params}
          onChangeParams={onChangeParams}
          onChangeThousandToast={onChangeThousandToast}
          includeWebAccess={includeWebAccess}
          onChangeWebPassword={onChangeWebPassword}
        />
      </Row>
      {shouldShowSaveButton && (
        <Row>
          <Col>
            <div className="text-end">
              <button type="submit" className="btn btn-success save-user">
                {isSaving && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
                Salvar
              </button>
            </div>
          </Col>
        </Row>
      )}
    </Form>
  )
}
