export function prepareEditRequestPayload({
  INITIAL_PERMISSIONS,
  permissionsPayload,
  params,
  user,
  name,
  email,
  password
}) {
  const requestPayload = {
    user_id: user?.user_id,
    data: {}
  }

  Object.entries(permissionsPayload).forEach(
    ([permissionKey, permissionValue]) => {
      if (INITIAL_PERMISSIONS[permissionKey] !== permissionValue) {
        requestPayload.data[permissionKey] = permissionValue
      }
    }
  )

  if (params?.client.id !== user.id_parent) {
    requestPayload.data.id_parent = params?.client.id
  }

  if (params?.profile.value !== user.profile) {
    requestPayload.data.roles = params?.profile.roles
  }

  switch (params?.profile.value) {
    case 'Cobrador ':
      requestPayload.data.unidade = params?.collector.id
      break

    case 'Gerente Regiao':
      requestPayload.data.regiaos = params?.regions.map(region => region.id)
      break
  }

  if (name !== user.name) {
    requestPayload.data.name = name
  }

  if (email !== user.email) {
    requestPayload.data.email = email
  }

  if (password !== '') {
    requestPayload.data.password = password
  }

  return requestPayload
}
