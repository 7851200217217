import { api } from '../../services/api'

export async function fetchCollectors({ id_parent, forSelectInput = true }) {
  const { data } = await api.get('/collectors', {
    params: {
      id_parent
    }
  })

  if (forSelectInput) {
    return data.map(collector => ({
      ...collector,
      label: collector.alias,
      value: collector.alias
    }))
  }

  return data
}
