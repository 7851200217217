import NonAuthLayout from 'components/NonAuthLayout'
import VerticalLayout from 'components/VerticalLayout'
import { Awards } from 'pages/Awards'
import { Clients } from 'pages/Clients'
import { Devices } from 'pages/Devices'
import { Login } from 'pages/Login'
import { Lotteries } from 'pages/Lotteries'
import { PaymentTables } from 'pages/PaymentTables'
import { Users } from 'pages/Users'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Dashboard } from '../pages/Dashboard/index'

export const routes = [
  { path: '/login', component: Login },
  { path: '/painel', component: Dashboard },
  { path: '/clientes', component: Clients },
  { path: '/loterias', component: Lotteries },
  { path: '/premiadas', component: Awards },
  { path: '/tabelas-de-pagamento', component: PaymentTables },
  { path: '/usuarios', component: Users },
  { path: '/dispositivos', component: Devices },
  { path: '/', exact: true, component: () => <Redirect to="/painel" /> }
]

export function Routes() {
  return routes.map(({ component: Component, path, ...rest }) => {
    return (
      <Route
        {...rest}
        key={path}
        path={path}
        render={props => {
          if (!localStorage.getItem('trivo-dash@user') && path !== '/login') {
            return (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            )
          }

          const isNonAuthLayout = path === '/login'

          if (isNonAuthLayout) {
            return (
              <NonAuthLayout>
                <Component {...props} />
              </NonAuthLayout>
            )
          }

          return (
            <VerticalLayout>
              <Component {...props} />
            </VerticalLayout>
          )
        }}
      />
    )
  })
}
