import { fetchClients } from 'core/http/fetchClients'
import { fetchCollectors } from 'core/http/fetchCollectors'
import { fetchProfiles } from 'core/http/fetchProfiles'
import { fetchRegions } from 'core/http/fetchRegions'
import { useHttp } from 'hooks/useHttp'
import React from 'react'
import Select from 'react-select'
import {
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from 'reactstrap'

export function InformationsSection({
  validation,
  params,
  onChangeParams,
  errors
}) {
  const { data: collectors, isFetching: isCollectorsFetching } = useHttp(
    ['collectors', params?.client],
    () =>
      fetchCollectors({
        id_parent: params?.client?.id
      }),
    {
      enabled: !!params?.client && params.profile?.value === 'Cobrador ',
      refetchOnWindowFocus: false
    }
  )

  const { data: regions, isFetching: isRegionsFetching } = useHttp(
    ['regions', params?.client],
    () =>
      fetchRegions({
        id_parent: params?.client?.id
      }),
    {
      enabled: !!params?.client && params.profile?.value === 'Gerente Regiao',
      refetchOnWindowFocus: false,
      select: regions => {
        if (params.client) {
          return regions.filter(
            region => region.id_unidade === params.client.id
          )
        }

        return regions
      }
    }
  )

  const { data: profiles, isLoading: isProfilesLoading } = useHttp(
    ['profiles'],
    fetchProfiles,
    {
      refetchOnWindowFocus: false
    }
  )

  const { data: clients, isLoading: isClientsLoading } = useHttp(
    ['clients'],
    fetchClients,
    {
      refetchOnWindowFocus: false
    }
  )

  return (
    <Row>
      <Col lg="12">
        <CardTitle className="mb-4">Informações</CardTitle>
        <Form>
          <Row>
            <Col lg="4">
              <div className="mb-3">
                <Label className="form-label">Nome</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder="Nome aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ''}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label className="form-label">E-mail</Label>
                <Input
                  name="email"
                  type="text"
                  placeholder="E-mail aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label className="form-label">Senha</Label>
                <Input
                  name="password"
                  type="text"
                  placeholder="Senha aqui"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ''}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>
        <Col lg="12">
          <div className="mb-3">
            <Label className="control-label">Banca</Label>
            <Select
              value={params?.client ?? []}
              onChange={client => onChangeParams('client', client)}
              placeholder="Selecionar banca"
              options={
                isClientsLoading
                  ? [{ label: 'Carregando...', value: 'Carregando...' }]
                  : clients
              }
            />
            {errors.client && (
              <span className="error-feedback">{errors.client.message}</span>
            )}
          </div>
        </Col>
        {!!params?.client && (
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label className="control-label">Perfil</Label>
                <Select
                  value={params?.profile ?? []}
                  onChange={profile => onChangeParams('profile', profile)}
                  placeholder="Selecionar perfil"
                  options={
                    isProfilesLoading
                      ? [{ label: 'Carregando...', value: 'Carregando...' }]
                      : profiles
                  }
                />
                {errors.profile && (
                  <span className="error-feedback">
                    {errors.profile.message}
                  </span>
                )}
              </div>
            </Col>
            {params?.profile?.value === 'Gerente Regiao' && (
              <Col lg="6">
                <div className="mb-3">
                  <Label className="control-label">Região</Label>
                  <Select
                    value={params?.regions ?? []}
                    isMulti
                    onChange={region => onChangeParams('regions', region)}
                    placeholder="Selecionar"
                    options={
                      isRegionsFetching
                        ? [{ label: 'Carregando...', value: 'Carregando...' }]
                        : regions
                    }
                  />

                  {errors.regions && (
                    <span className="error-feedback">
                      {errors.regions.message}
                    </span>
                  )}
                </div>
              </Col>
            )}
            {params?.profile?.value === 'Cobrador ' && (
              <Col lg="6">
                <div className="mb-3">
                  <Label className="control-label">Cobrador</Label>
                  <Select
                    value={params?.collector ?? []}
                    onChange={collector =>
                      onChangeParams('collector', collector)
                    }
                    placeholder="Selecionar cobrador"
                    options={
                      isCollectorsFetching
                        ? [{ label: 'Carregando...', value: 'Carregando...' }]
                        : collectors
                    }
                  />
                  {errors.collector && (
                    <span className="error-feedback">
                      {errors.collector.message}
                    </span>
                  )}
                </div>
              </Col>
            )}
          </Row>
        )}
      </Col>
    </Row>
  )
}
