import Breadcrumbs from 'components/Common/Breadcrumb'
import { Pagination } from 'components/Common/Pagination'
import { useHttp } from 'hooks/useHttp'
import React, { useCallback, useMemo, useState } from 'react'
import { Search } from 'react-bootstrap-table2-toolkit'
import MetaTags from 'react-meta-tags'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import { fetchUsers } from './http/fetchUsers'
import { EditUserModal } from './modals/EditUserModal/'
import { NewUserModal } from './modals/NewUserModal/'

export function Users() {
  const { SearchBar } = Search

  const { data, isSuccess, isLoading } = useHttp(['users'], fetchUsers, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5 // 5 minutes
  })

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [user, setUser] = useState(null)

  const users = useMemo(() => {
    return isSuccess ? data : []
  }, [isSuccess, data])

  const handleCallEditUser = useCallback(
    (props, { shouldCloneUser = false }) => {
      const [userData] = users.filter(
        user => user.name === props.cell.row.values.name
      )

      setUser({ ...userData, shouldCloneUser })
      setIsEditUserModalOpen(true)
    },
    [users]
  )

  const columns = useMemo(() => {
    return [
      {
        accessor: 'user_id',
        Header: 'Id'
      },
      {
        accessor: 'name',
        Header: 'Nome'
      },
      {
        accessor: 'email',
        Header: 'E-mail'
      },
      {
        accessor: 'profile',
        Header: 'Role'
      },
      {
        accessor: 'id_parent',
        Header: 'Id parent'
      },
      {
        accessor: 'alias',
        Header: 'Banca'
      },
      {
        accessor: '',
        Header: 'Ações',
        Cell: props => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop cursor-pointer"
                tag="div"
              >
                <i className="mdi mdi-pencil font-size-14 text-success me-1" />
                <span className="font-size-14 text-success text-normal">
                  Editar
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => handleCallEditUser(props, {})}
                  className="d-flex align-center"
                >
                  <i className="mdi mdi-pencil font-size-16  me-1" /> Editar
                  usuário
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleCallEditUser(props, { shouldCloneUser: true })
                  }
                  className="d-flex align-center"
                >
                  <i className="bx bx-user-plus font-size-16 me-1" /> Duplicar
                  usuário
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
    ]
  }, [handleCallEditUser])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter, pageSize, pageIndex },
    page,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: users,
      initialState: {
        pageSize: 15
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Usuários" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">Usuários </CardTitle>
                      <p className="card-title-desc">Tabela de usuários</p>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => setIsNewUserModalOpen(true)}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Novo usuário
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Buscar"
                            onSearch={setGlobalFilter}
                            searchText={globalFilter}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <th
                                key={index}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {isLoading ? (
                        <div className="mh-20 border-none">
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, index) => {
                            prepareRow(row)
                            return (
                              <tr key={index} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex align-center">
                    <span>Total de {users?.length} registros</span>
                    <Pagination
                      onNextPage={nextPage}
                      onPreviousPage={previousPage}
                      onChangePage={page => gotoPage(page)}
                      registersPerPage={pageSize}
                      totalPageCount={users?.length}
                      currentPage={pageIndex + 1}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <NewUserModal
        isOpen={isNewUserModalOpen}
        onToggle={() => {
          setIsNewUserModalOpen(false)
          setUser(null)
        }}
      />

      <EditUserModal
        user={user}
        isOpen={isEditUserModalOpen}
        onToggle={() => {
          setIsEditUserModalOpen(false)
          setUser(null)
        }}
      />
    </>
  )
}
