import Breadcrumbs from 'components/Common/Breadcrumb'
import { Pagination } from 'components/Common/Pagination'
import { useHttp } from 'hooks/useHttp'
import React, { useCallback, useMemo, useState } from 'react'
import { Search } from 'react-bootstrap-table2-toolkit'
import MetaTags from 'react-meta-tags'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import { fetchPaymentTb } from '../../core/http/fetchPaymentTb'
import { CloneTableModal } from './modals/CloneTableModal'
import { EditTableModal } from './modals/EditTableModal'

export function PaymentTables() {
  const { SearchBar } = Search

  const [isEditTableModalOpen, setIsEditTableModalOpen] = useState(false)
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  const [selectedTable, setSelectedTable] = useState(null)

  const {
    data: tables,
    isLoading: isPaymentTbLoading,
    isSuccess
  } = useHttp(['paymentTb'], fetchPaymentTb, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5 // minutes,
  })

  const paymentTbs = useMemo(() => {
    return isSuccess ? tables : []
  }, [isSuccess, tables])

  const handleCallModalAction = useCallback((key, props) => {
    setSelectedTable(props.row.original)

    switch (key) {
      case 'edit':
        setIsEditTableModalOpen(true)
        break

      case 'clone':
        setIsCloneModalOpen(true)
        break
    }
  }, [])

  const columns = useMemo(() => {
    return [
      {
        accessor: 'id',
        Header: 'Id'
      },
      {
        accessor: 'banca_alias',
        Header: 'Banca'
      },
      {
        accessor: 'alias',
        Header: 'Alias'
      },
      {
        accessor: 'indice',
        Header: 'Índice'
      },
      {
        accessor: '',
        Header: 'Ações',
        Cell: props => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop cursor-pointer"
                tag="div"
              >
                <i className="mdi mdi-pencil font-size-14 text-success me-1" />
                <span className="font-size-14 text-success text-normal">
                  Editar
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => handleCallModalAction('edit', props)}
                  className="d-flex align-center"
                >
                  <i className="mdi mdi-pencil font-size-16 me-1" /> Editar
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCallModalAction('clone', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-duplicate font-size-16 me-1" /> Duplicar
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCallModalAction('remove', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-x font-size-16 me-1" /> Remover
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
    ]
  }, [handleCallModalAction])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageSize, pageIndex, globalFilter },
    page,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: paymentTbs,
      initialState: {
        pageSize: 15
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Tabelas de pagamento" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">
                        Tabelas de pagamento{' '}
                      </CardTitle>
                      <p className="card-title-desc">Tabelas registradas</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Buscar"
                            onSearch={setGlobalFilter}
                            searchText={globalFilter}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <th
                                key={index}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      {isPaymentTbLoading ? (
                        <div className="mh-20 border-none">
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, index) => {
                            prepareRow(row)
                            return (
                              <tr key={index} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex align-center">
                    <span>Total de {paymentTbs?.length} registros</span>
                    <Pagination
                      onNextPage={nextPage}
                      onPreviousPage={previousPage}
                      onChangePage={page => gotoPage(page)}
                      registersPerPage={pageSize}
                      totalPageCount={paymentTbs?.length}
                      currentPage={pageIndex + 1}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <EditTableModal
        isOpen={isEditTableModalOpen}
        onToggle={() => setIsEditTableModalOpen(false)}
        table={selectedTable}
      />

      <CloneTableModal
        isOpen={isCloneModalOpen}
        onToggle={() => setIsCloneModalOpen(false)}
        table={selectedTable}
      />
    </>
  )
}
