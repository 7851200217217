import { api } from 'services/api'

export async function updateClientProfile(payload, original, id_parent) {
  const requestPayload = {}

  Object.entries(payload).map(([key, value]) => {
    if (original[key] !== value) {
      requestPayload[key] = value
    }
  })

  const { data } = await api.put('/clients/profile', {
    id_parent,
    data: requestPayload
  })

  return data
}
