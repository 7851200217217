import { api } from 'services/api'

export async function fetchUnits({ forSelectInput = true }) {
  const { data } = await api.get('/units')

  if (forSelectInput) {
    return data.map(unit => ({
      ...unit,
      label: unit.alias,
      value: unit.alias
    }))
  }

  return data
}
