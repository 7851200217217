import { Pagination } from 'components/Common/Pagination'
import React, { useMemo, useState } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

export function AlterationRegister() {
  const columns = useMemo(() => {
    return [
      {
        accessor: 'updated_at',
        Header: 'Alterado em'
      },
      {
        accessor: 'origem',
        Header: 'Origem'
      },
      {
        accessor: '',
        Header: 'Unidade'
      },
      {
        accessor: '',
        Header: 'Comissão Ant.'
      },
      {
        accessor: '',
        Header: 'Comissão atual'
      },
      {
        accessor: '',
        Header: 'Cobrador Ant.'
      },
      {
        accessor: '',
        Header: 'Cobrador atual'
      }
    ]
  }, [])

  const [logs] = useState([])
  const pageSize = 5

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: logs,
      initialState: {
        pageSize: 5
      }
    },
    useSortBy,
    usePagination
  )

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <CardTitle className="h4">Registro de alterações </CardTitle>
            <p className="card-title-desc">Registro de alterações</p>

            <div className="table-responsive">
              <table
                className="table align-middle table-nowrap"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr key={index} {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td key={index} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <Pagination
              onChangePage={index => gotoPage(index)}
              onNextPage={nextPage}
              onPreviousPage={previousPage}
              totalPageCount={logs.length / pageSize}
              currentPage={pageIndex}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
