export function formatCurrencyToApi(value) {
  try {
    const valueUncurrency = value.replace(/[^0-9\.-]+/g, '').replace('.', '')
    const valueFormatted = `${valueUncurrency.slice(
      0,
      -2
    )}.${valueUncurrency.slice(-2)}`

    return valueFormatted
  } catch (err) {
    return null
  }
}
