import flatpickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import React, { forwardRef, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import Select from 'react-select'
import { Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap'
import { api } from 'services/api'
import { fetchLotteryGroups } from '../../../core/http/fetchLotteryGroups'

export const FiltersComponent = ({ onChangeFilters, filters }, ref) => {
  const queryClient = useQueryClient()

  const { data: lotteryGroups, isLoading: isLotteryGroupsLoading } = useQuery(
    'lotteryGroups',
    fetchLotteryGroups,
    {
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  )

  const cachedClients = queryClient.getQueryData('clients')
  const { data: clientsData, isLoading: isClientsLoading } = useQuery(
    'clients',
    async () => {
      const { data } = await api.get('/clients')

      return data
    },
    {
      enabled: cachedClients === undefined
    }
  )

  const clients = useMemo(() => {
    if (cachedClients !== undefined) {
      return cachedClients.map(client => ({
        ...client,
        label: client.alias,
        value: client.alias
      }))
    }

    return clientsData?.map(client => ({
      ...client,
      label: client.alias,
      value: client.alias
    }))
  }, [cachedClients, clientsData])

  useEffect(() => {
    flatpickr(ref.current, {
      mode: 'range',
      dateFormat: 'd/m/Y',
      locale: Portuguese,
      onChange: value => {
        const [start_date, end_date] = value
        if (end_date) {
          onChangeFilters('period', {
            dates: {
              start_date,
              end_date
            }
          })
        }
      }
    })
  }, [onChangeFilters])

  return (
    <Row>
      <Col lg="4">
        <FormGroup className="mb-3">
          <Label>Período</Label>
          <InputGroup>
            <Input
              innerRef={ref}
              className="bg-white"
              placeholder="Selecionar período"
            />
          </InputGroup>
        </FormGroup>
      </Col>
      <Col lg="4">
        <div className="mb-3">
          <label className="control-label">Cliente</label>
          <Select
            value={filters?.client || []}
            onChange={value => onChangeFilters('client', value)}
            placeholder="Selecionar cliente"
            options={
              isClientsLoading
                ? [{ label: 'Carregando...', value: 'Carregando...' }]
                : clients
            }
          />
        </div>
      </Col>
      <Col lg="4">
        <div className="mb-3">
          <label className="control-label">Loteria</label>
          <Select
            value={filters?.lottery || []}
            onChange={value => onChangeFilters('lottery', value)}
            placeholder="Selecionar loteria"
            options={
              isLotteryGroupsLoading
                ? [{ label: 'Carregando...', value: 'Carregando...' }]
                : lotteryGroups
            }
          />
        </div>
      </Col>
    </Row>
  )
}

export const Filters = forwardRef(FiltersComponent)
