import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { updateClientProfile } from '../http/updateClientProfile'

export function ProfileModal({ isOpen, onToggle, client }) {
  const queryClient = useQueryClient()
  const [isUpdatingClient, setIsUpdatingClient] = useState()

  const notifySuccess = () => {
    toast.success('Perfil alterado com sucesso')
  }

  const notifyError = () => {
    toast.error('Erro ao alterar perfil')
  }

  async function handleUpdateClientProfile(values) {
    const currentClientData = {
      alias: client?.alias,
      slug: client?.slug,
      uuid: client?.uuid
    }

    if (JSON.stringify(values) === JSON.stringify(currentClientData)) {
      return
    }

    setIsUpdatingClient(true)

    try {
      await updateClientProfile(
        values,
        {
          alias: client?.alias,
          slug: client?.slug,
          uuid: client?.uuid
        },
        client?.id_parent
      )

      const newClientData = {
        ...client,
        ...values,
        alias: values.alias.toUpperCase()
      }

      queryClient.setQueryData('clients', currentData => {
        return currentData.map(client => {
          if (client.id === newClientData.id) {
            return newClientData
          }

          return client
        })
      })

      notifySuccess()
      onToggle()
    } catch (err) {
      notifyError()
    }

    setIsUpdatingClient(false)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      alias: client?.alias,
      slug: client?.slug,
      uuid: client?.uuid
    },
    onSubmit: values => {
      handleUpdateClientProfile(values)
    }
  })

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} tag="h4">
        Perfil
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Nome</Label>
                <Input
                  name="alias"
                  type="text"
                  style={{ textTransform: 'uppercase' }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.alias || ''}
                  invalid={
                    validation.touched.alias && validation.errors.alias
                      ? true
                      : false
                  }
                />
                {validation.touched.alias && validation.errors.alias ? (
                  <FormFeedback type="invalid">
                    {validation.errors.alias}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Slug</Label>
                <Input
                  name="slug"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.slug || ''}
                  invalid={
                    validation.touched.slug && validation.errors.slug
                      ? true
                      : false
                  }
                />
                {validation.touched.slug && validation.errors.slug ? (
                  <FormFeedback type="invalid">
                    {validation.errors.slug}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Uuid</Label>
                <Input
                  name="uuid"
                  type="numeric"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.uuid || ''}
                  invalid={
                    validation.touched.uuid && validation.errors.uuid
                      ? true
                      : false
                  }
                />
                {validation.touched.uuid && validation.errors.uuid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.slug}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {isUpdatingClient && (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  )}
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
