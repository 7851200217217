import React from 'react'
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap'

export function AwardModal({ isOpen, onToggle, client }) {
  return (
    <Modal isOpen={isOpen} toggle={onToggle} scrollable>
      <ModalHeader toggle={onToggle} tag="h4">
        Premiação
      </ModalHeader>
      <ModalBody>
        <Col>
          <></>
        </Col>
      </ModalBody>
    </Modal>
  )
}
