import { useFormik } from 'formik'
import React from 'react'
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'

export function PaymentModal({ isOpen, onToggle, client }) {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      pix: ''
    },
    onSubmit: values => {
      console.log(values)
    }
  })

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} tag="h4">
        Pagamento
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Chave PIX</Label>
                <Input
                  name="pix"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.pix || ''}
                  invalid={
                    validation.touched.pix && validation.errors.pix
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Client_Id</Label>
                <Input
                  name="clientId"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.slug || ''}
                  invalid={
                    validation.touched.slug && validation.errors.slug
                      ? true
                      : false
                  }
                />
                {validation.touched.slug && validation.errors.slug ? (
                  <FormFeedback type="invalid">
                    {validation.errors.slug}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Client_Secret</Label>
                <Input
                  name="clientSecret"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.uuid || ''}
                  invalid={
                    validation.touched.uuid && validation.errors.uuid
                      ? true
                      : false
                  }
                />
                {validation.touched.uuid && validation.errors.uuid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.slug}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
