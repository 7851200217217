import { useQuery, useQueryClient } from 'react-query'

export function useHttp(queryConfigs, fetchCallback, queryProps) {
  const queryClient = useQueryClient()
  const useQueryProps = {
    ...queryProps
  }

  const [queryId] = queryConfigs
  const cachedData = queryClient.getQueryData(queryId)

  if (cachedData) {
    useQueryProps.initialData = cachedData
    useQueryProps.enabled = false
  }

  return useQuery(queryConfigs, fetchCallback, useQueryProps)
}
