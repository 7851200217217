import { useFormik } from 'formik'
import { currencyFormat } from 'helpers/currencyFormat'
import { formatCurrencyToApi } from 'helpers/formatCurrencyToApi'
import { ConfigsForm } from 'pages/Clients/components/ConfigsForm'
import React, { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import * as Yup from 'yup'
import { updateClientParams } from '../../http/updateClientParams'

export function ConfigsModal({ isOpen, onToggle, client }) {
  const queryClient = useQueryClient()
  const [isUpdatingClient, setIsUpdatingClient] = useState(false)
  const [paramsPayload, setParamsPayload] = useState({})

  const notifySuccess = () => {
    toast.success('Configurações alteradas com sucesso!')
  }

  const notifyError = () => {
    toast.error('Erro ao alterar configurações')
  }

  console.log(client)

  const params = useMemo(() => {
    if (client) {
      const data = {}

      client.unidades_params.forEach(param => {
        if (
          param.param_name !== 'limite_repasse' &&
          param.param_name !== 'lic_mobile'
        ) {
          data[param.param_name] = param.param_value
        }
      })

      return {
        ...data,
        ...paramsPayload
      }
    }
  }, [client, paramsPayload])

  const handleChangeParams = useCallback(
    paramKey => {
      let newParams = {}

      if (paramKey === 'mbrinde_enabled') {
        newParams['mbrinde'] = {
          ...params['mbrinde'],
          enabled: Number(params['mbrinde'].enabled) === 1 ? '0' : '1'
        }
      } else {
        newParams[paramKey] = Number(params[paramKey]) === 1 ? '0' : '1'
      }

      setParamsPayload(currentState => {
        return { ...currentState, ...newParams }
      })
    },
    [params]
  )

  const handleAddListed = useCallback(
    listed => {
      if (!params.hasOwnProperty('cotadas')) {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [listed]
        }))
      } else if (!paramsPayload.hasOwnProperty('cotadas')) {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [...params.cotadas, listed]
        }))
      } else {
        setParamsPayload(currentState => ({
          ...currentState,
          cotadas: [...currentState.cotadas, listed]
        }))
      }
    },
    [params, paramsPayload]
  )

  const handleChangeThousandToast = useCallback(
    (_, value) => {
      const newParams = {
        mbrinde: {
          ...params.mbrinde,
          valor: value
        }
      }

      setParamsPayload(currentState => {
        return { ...currentState, ...newParams }
      })
    },
    [params]
  )

  async function handleSubmitClientParams(data) {
    setIsUpdatingClient(true)

    try {
      const sendData = {
        ...data,
        ...paramsPayload,
        limite_jogo: formatCurrencyToApi(data.limite_jogo)
      }

      if (sendData['mbrinde']) {
        sendData['mbrinde'].valor = String(
          Number(sendData['mbrinde'].valor).toFixed(2)
        )
      }

      await updateClientParams({
        data: sendData,
        id_unidade: client?.id
      })

      const newParams = {}

      Object.entries(params).map(([key, value]) => {
        newParams[key] = [[value]]
      })

      const newClientData = {
        ...client,
        limite_jogo: formatCurrencyToApi(data.limite_jogo),
        params: newParams
      }

      queryClient.setQueryData('clients', currentData => {
        return currentData.map(client => {
          if (client.id === newClientData.id) {
            return newClientData
          }
          return client
        })
      })

      notifySuccess()
      onToggle()
    } catch (err) {
      notifyError()
    }

    setIsUpdatingClient(false)
  }

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    initialValues: {
      header_title: params?.header_title,
      msg_apostas: params?.msg_apostas,
      reclame_dias: params?.reclame_dias,
      limite_jogo: currencyFormat(params?.limite_jogo) ?? '',
      pule_cancela: Number(params?.pule_cancela),
      bg: params?.theme?.bg,
      c1: params?.theme?.c1,
      c2: params?.theme?.c2
    },
    onSubmit: values => {
      handleSubmitClientParams({ ...values })
    }
  })

  return (
    <Modal isOpen={isOpen} toggle={onToggle} scrollable size="xl">
      <ModalHeader
        toggle={() => {
          setParamsPayload({})
          onToggle()
        }}
        tag="h4"
      >
        Configurações
      </ModalHeader>
      <ModalBody>
        <ConfigsForm
          validation={validation}
          params={params}
          isSaving={isUpdatingClient}
          onAddListed={handleAddListed}
          onChangeParams={handleChangeParams}
          onChangeThousandToast={handleChangeThousandToast}
        />
      </ModalBody>
    </Modal>
  )
}
