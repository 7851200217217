import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function Toast() {
  return (
    <ToastContainer
      position="top-center"
      theme="colored"
      hideProgressBar
      closeOnClick
      autoClose={1000}
    />
  )
}
