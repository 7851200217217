import { currencyFormat } from 'helpers/currencyFormat'
import React, { useMemo } from 'react'
import CurrencyInput from 'react-currency-input'
import { CardTitle, Col, Input } from 'reactstrap'

export function ParamsSection({
  params,
  onChangeParams,
  onChangeThousandToast,
  includeWebAccess,
  onChangeWebPassword = () => null
}) {
  const thousandToastValueFormatted = useMemo(() => {
    return currencyFormat(params?.mbrinde?.valor)
  }, [params])

  return (
    <>
      <CardTitle className="mb-4 mt-4">Parâmetros</CardTitle>
      <Col lg="3">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.lti_enabled) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('lti_enabled')}
          />
          <label className="form-check-label ml-6">Loteria instantânea</label>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.pp) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('pp')}
          />
          <label className="form-check-label ml-6">Pule premiada</label>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.mbrinde?.enabled) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('mbrinde_enabled')}
          />
          <label className="form-check-label ml-6">Milhar brinde</label>
        </div>
        {Number(params?.mbrinde?.enabled) === 1 && (
          <div className="form-floating mb-3">
            <CurrencyInput
              name="limite_jogo"
              prefix="R$"
              type="money"
              inputMode="decimal"
              className="form-control input-color"
              placeholder="0,00"
              decimalSeparator=","
              thousandSeparator="."
              onChangeEvent={(e, value, maskedValue) => {
                onChangeThousandToast(value, maskedValue)
              }}
              value={thousandToastValueFormatted}
            />
            <label htmlFor="floatingnameInput">Valor mínimo da pule</label>
          </div>
        )}
      </Col>
      <Col lg="3">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.consulta_pule) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('consulta_pule')}
          />
          <label className="form-check-label ml-6">Consultar pule</label>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.premios_reter) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('premios_reter')}
          />
          <label className="form-check-label ml-6">Reter prêmios</label>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={Number(params?.foot_enabled) === 1}
            onChange={() => {}}
            onClick={() => onChangeParams('foot_enabled')}
          />
          <label className="form-check-label ml-6">Futebol habilitado</label>
        </div>
        {includeWebAccess && (
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={Number(params?.acessoweb) === 1}
              onChange={() => {}}
              onClick={() => onChangeParams('acessoweb')}
            />
            <label className="form-check-label ml-6">Acesso web</label>
          </div>
        )}
        {Number(params?.acessoweb) === 1 && (
          <div className="mb-3">
            <Input
              name="password"
              type="text"
              placeholder="Senha"
              value={params?.webpassword}
              onChange={e => onChangeWebPassword(e.target.value)}
            />
          </div>
        )}
      </Col>
    </>
  )
}
