import { fetchLotteryGroups } from 'core/http/fetchLotteryGroups'
import { fetchPaymentTb } from 'core/http/fetchPaymentTb'
import { useHttp } from 'hooks/useHttp'
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import Select from 'react-select'
import { Form, Row, TabPane } from 'reactstrap'

const LotteryPaymentStepElement = (_, ref) => {
  const [selectedLotteryGroups, setSelectedLotteryGroups] = useState([])
  const [selectedPaymentTbs, setSelectedPaymentTbs] = useState([])

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return {
          lotteryGroups: selectedLotteryGroups,
          paymentTbs: selectedPaymentTbs
        }
      }
    }
  })

  const {
    data: paymentTbsRaw,
    isLoading: isPaymentTbLoading,
    isSuccess: isSuccessPaymentTb
  } = useHttp(['paymentTb'], fetchPaymentTb, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    select: paymentTbs =>
      paymentTbs.filter(paymentTb => paymentTb.banca_alias === 'ADMIN')
  })

  const paymentTbsOptions = useMemo(() => {
    if (isSuccessPaymentTb) {
      const paymentTbsFormattedForSelectInput = [
        { label: 'Selecionar todas', value: 'all' }
      ]

      paymentTbsRaw.forEach(paymentTb => {
        paymentTbsFormattedForSelectInput.push({
          label: paymentTb.alias,
          value: paymentTb
        })
      })

      return paymentTbsFormattedForSelectInput
    }
  }, [isSuccessPaymentTb])

  const {
    data: lotteryGroupsRaw,
    isLoading: isLoadingLotteryGroups,
    isSuccess: isSuccessLotteryGroups
  } = useHttp(['lotteryGroups'], fetchLotteryGroups, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5
  })

  const lotteryGroupsOptions = useMemo(() => {
    if (isSuccessLotteryGroups) {
      const lotteryGroupsFormattedForSelectInput = [
        { label: 'Selecionar todas', value: 'all' }
      ]

      lotteryGroupsRaw.forEach(lotteryGroup => {
        lotteryGroupsFormattedForSelectInput.push({
          label: lotteryGroup.label,
          value: lotteryGroup.options
        })
      })

      return lotteryGroupsFormattedForSelectInput
    }
  }, [isSuccessLotteryGroups, lotteryGroupsRaw])

  function handleSelectLotteryGroup(groups) {
    const lastIndexGroups = groups[groups.length - 1]

    if (lastIndexGroups?.value === 'all') {
      const lotteryGroupsFilteredAllOption = lotteryGroupsOptions.filter(
        lotteryGroup => lotteryGroup.value !== 'all'
      )

      setSelectedLotteryGroups(lotteryGroupsFilteredAllOption)
    } else {
      setSelectedLotteryGroups(groups)
    }
  }

  function handleSelectPaymentTb(paymentTbs) {
    const lastIndexPaymentTbs = paymentTbs[paymentTbs.length - 1]

    if (lastIndexPaymentTbs?.value === 'all') {
      const paymentTbsFilteredAllOption = paymentTbsOptions.filter(
        paymentTb => paymentTb.value !== 'all'
      )

      setSelectedPaymentTbs(paymentTbsFilteredAllOption)
    } else {
      setSelectedPaymentTbs(paymentTbs)
    }
  }

  return (
    <TabPane tabId={2}>
      <div>
        <Form>
          <Row>
            <div className="mb-3">
              <label className="control-label">Loterias</label>
              <Select
                value={selectedLotteryGroups}
                isMulti
                onChange={handleSelectLotteryGroup}
                placeholder="Selecionar"
                options={
                  isLoadingLotteryGroups
                    ? [{ label: 'Carregando...', value: 'Carregando...' }]
                    : lotteryGroupsOptions
                }
              />
            </div>
          </Row>
          <Row>
            <div className="mb-3">
              <label className="control-label">Tabelas de pagamento</label>
              <Select
                value={selectedPaymentTbs}
                isMulti
                onChange={handleSelectPaymentTb}
                placeholder="Selecionar"
                options={
                  isPaymentTbLoading
                    ? [{ label: 'Carregando...', value: 'Carregando...' }]
                    : paymentTbsOptions
                }
              />
            </div>
          </Row>
        </Form>
      </div>
    </TabPane>
  )
}

export const LotteryPaymentStep = forwardRef(LotteryPaymentStepElement)
