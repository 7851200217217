import { Pagination } from 'components/Common/Pagination'
import { useHttp } from 'hooks/useHttp'
import React, { useCallback, useMemo, useState } from 'react'
import { Search } from 'react-bootstrap-table2-toolkit'
import MetaTags from 'react-meta-tags'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { capitalizeTitle } from '../../helpers/capitalizeTitle'
import { Filters } from './components/Filters'
import { fetchDevices } from './http/fetchDevices'
import { EditDeviceModal } from './modals/EditDeviceModal'

export function Devices() {
  const { SearchBar } = Search

  const [isEditDeviceModalOpen, setIsEditDeviceModalOpen] = useState(false)
  const [device, setDevice] = useState(null)
  const [filters, setFilters] = useState({})

  const { data, isLoading, isSuccess } = useHttp(['devices'], fetchDevices, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    select: devices => {
      let returnDevices = devices

      if (filters.region && filters.region.value !== 'all') {
        returnDevices = returnDevices.filter(
          device => device.regiao === filters.region.value
        )
      }

      if (filters.client && filters.client.value !== 'all') {
        returnDevices = returnDevices.filter(
          device => device.id_parent === filters.client.id
        )
      }

      return returnDevices
    }
  })

  const devices = useMemo(() => {
    return isSuccess ? data : []
  }, [isSuccess, data])

  const handleCallModalAction = useCallback((key, props) => {
    const selectedDevice = props.row.original

    setDevice(selectedDevice)

    switch (key) {
      case 'edit':
        setIsEditDeviceModalOpen(true)
        break
    }
  }, [])

  const handleChangeFilters = useCallback((key, filter) => {
    const newFilters = {
      [key]: filter
    }

    setFilters(currentFilters => ({ ...currentFilters, ...newFilters }))
  }, [])

  const columns = useMemo(() => {
    return [
      {
        accessor: 'id',
        Header: 'Id'
      },
      {
        accessor: 'tipo',
        Header: 'Tipo'
      },
      {
        accessor: 'serial',
        Header: 'Serial'
      },
      {
        accessor: 'modelo',
        Header: 'Modelo'
      },
      {
        accessor: 'chip',
        Header: 'Chip'
      },
      {
        accessor: 'id_unidade, unidade, regiao',
        Header: 'Unidade Região',
        Cell: props => {
          return (
            props.row.original.id_unidade && (
              <>
                <h6 className="font-size-14 mb-1">
                  <span>
                    {props.row.original?.id_unidade} |{' '}
                    {props.row.original?.unidade}
                  </span>
                </h6>
                <p className="text-muted mb-0">{props.row.original?.regiao}</p>
              </>
            )
          )
        }
      },
      {
        accessor: 'descricao',
        Header: 'Desc'
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ value }) => {
          const deviceStatusVariants = {
            pendente: 'text-warning',
            ativado: 'text-success',
            excluido: 'text-danger',
            desativado: 'text-danger'
          }

          return (
            <div
              className={`${deviceStatusVariants[value]} font-size-14 text-normal`}
            >
              {capitalizeTitle(value)}
            </div>
          )
        }
      },
      {
        accessor: 'lastlogin',
        Header: 'Última atividade'
      },
      {
        accessor: 'versao',
        Header: 'Versão'
      },
      {
        accessor: '',
        Header: 'Ações',
        Cell: props => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop cursor-pointer"
                tag="div"
              >
                <i className="mdi mdi-pencil font-size-14 text-success me-1" />
                <span className="font-size-14 text-success text-normal">
                  Editar
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => handleCallModalAction('edit', props)}
                  className="d-flex align-center"
                >
                  <i className="mdi mdi-pencil font-size-16  me-1" /> Editar
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCallModalAction('remove', props)}
                  className="d-flex align-center"
                >
                  <i className="bx bx-x font-size-16 me-1" /> Remover
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
    ]
  }, [handleCallModalAction])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter, pageSize, pageIndex },
    page,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: devices,
      initialState: {
        pageSize: 15
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Trivo | Painel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="user" breadcrumbItem="Dispositivos" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">Dispositivos </CardTitle>
                      <p className="card-title-desc">Lista de dispositivos</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Buscar"
                            onSearch={setGlobalFilter}
                            searchText={globalFilter}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Filters
                    onChangeFilters={handleChangeFilters}
                    filters={filters}
                    shouldFetchFilters={isSuccess}
                  />

                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup, index) => (
                          <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <th
                                key={index}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {isLoading ? (
                        <div className="mh-20 border-none">
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, index) => {
                            prepareRow(row)
                            return (
                              <tr key={index} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex align-center">
                    <span>Total de {devices?.length} registros</span>
                    <Pagination
                      onNextPage={nextPage}
                      onPreviousPage={previousPage}
                      onChangePage={page => gotoPage(page)}
                      registersPerPage={pageSize}
                      totalPageCount={devices?.length}
                      currentPage={pageIndex + 1}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <EditDeviceModal
        device={device}
        isOpen={isEditDeviceModalOpen}
        onToggle={() => setIsEditDeviceModalOpen(false)}
      />
    </>
  )
}
